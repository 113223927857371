import React, { useState, useEffect, useRef, Fragment } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useApplyContext } from "./context"
import { REST } from "./api-calls-map"
import SignAgreement from "./sign-agreement"
import ReviewBorrowerLoanAgreement from "./review-borrower-loan-agreement"
import GuarantorStatus from "./guarantor-status"
import UploadFiles from "./upload-files"
import SummaryActiveLoan from "./summary-active-loan"
import SummaryActiveLoanFunded from "./summary-active-loan-funded"
import SummaryActiveGuarantor from "./summary-active-guarantor"
import SummaryActiveGuarantorFunded from "./summary-active-guarantor-funded"
import SummaryIdleLoan from "./summary-idle-loan"
import SummaryIdleGuarantor from "./summary-idle-guarantor"
import InitialBorrowerFormState from "./initial-borrower-form-state"
import { navigate, Link } from "gatsby"

const isBrowser = typeof window !== "undefined"

const domain =
  isBrowser &&
  window &&
  window.location &&
  window.location.origin &&
  window.location.origin &&
  window.location.origin === "http://local.dev.trustic.com:8000"
    ? "http://dev.trustic.com"
    : isBrowser && window.location.origin

/*global dataLayer */
const Summary = ({ guarantor }) => {
  const [signAgreement, setSignAgreement] = useState(false)

  const [
    reviewBorrowerLoanAgreement,
    setReviewBorrowerLoanAgreement,
  ] = useState(false)

  const [trackGuarantor, setTrackGuarantor] = useState(false)

  const [activeItemId, setActiveItemId] = useState(null)

  const [stepLoading, setStepLoading] = useState(false)

  const [copied, setCopied] = useState(false)

  const {
    API_MAP,
    getLoanApplication,
    loanApplication,
    todoItems,
    getTodoItems,
    setShowTermsModal,
    setTermsModalContent,
    initiateNewLoanApplication,
    updateLoanApplication,
    setAppLoadingStatus,
    setAppLoading,
    setLinkBankLoading,
  } = useApplyContext()

  const getLoanRef = useRef()

  const getTodoItemsRef = useRef()

  const retryLoanDate = new Date(loanApplication.lastStatusUpdate)

  retryLoanDate.setDate(retryLoanDate.getDate() + 30)

  const Difference_In_Time =
    new Date().getTime() - new Date(loanApplication.lastStatusUpdate).getTime()

  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)

  const initLoan = async () => {
    setStepLoading(true)
    const [newApplication, status] = await initiateNewLoanApplication()

    if (status === 200) {
      localStorage.setItem("businessId", newApplication.businessId)
      localStorage.setItem("moneyReason", newApplication.purpose)
      dataLayer.push({
        borrower_app_business_id: newApplication.businessId,
      })

      setTimeout(() => {
        !guarantor && typeof window !== "undefined" && window.location.reload()
        if (guarantor && typeof window !== "undefined") {
          window.location = "/apply"
        }
      }, 1000)
    }
  }

  const playdLogin = async () => {
    setLinkBankLoading(true)
    setAppLoading(true)
    setAppLoadingStatus("Connecting your plaid account")

    const [res, status] = await REST({
      url: API_MAP.plaidLinkTokenUpdate,
      method: "POST",
    })

    if (status === 200 && res.linkToken) {
      /* global Plaid */
      localStorage.setItem("linkToken", res.linkToken)
      const handler = Plaid.create({
        token: res.linkToken,
        onSuccess: async (public_token, metadata) => {
          setAppLoading(true)
          setAppLoadingStatus("Connecting your plaid account")

          await REST({
            url: API_MAP.publicTokenUpdate,
            method: "POST",
            body: { text: public_token },
          })

          setAppLoadingStatus(null)
          setAppLoading(false)

          REST({
            url: `${API_MAP.sendPlaidEvent}`,
            method: "POST",
            body: {
              message: JSON.stringify({ public_token, metadata }),
            },
          })
        },
        onLoad: () => {
          setAppLoadingStatus(null)
          setAppLoading(false)
        },
        onExit: (err, metadata) => {
          setLinkBankLoading(false)
          REST({
            url: `${API_MAP.sendPlaidEvent}`,
            method: "POST",
            body: {
              message: JSON.stringify({ err, metadata }),
            },
          })
        },
        onEvent: (eventName, metadata) => {
          REST({
            url: `${API_MAP.sendPlaidEvent}`,
            method: "POST",
            body: {
              message: JSON.stringify({ eventName, metadata }),
            },
          })
        },
        receivedRedirectUri: null,
      })
      handler.open()
    }
  }

  useEffect(() => {
    const getLoanData = async () => {
      // let loan = await getLoanApplication(true)

      // let todoItems = await getTodoItems()

      let loan

      let todoItems

      if (
        !!loanApplication &&
        loanApplication.status !== "REJECTED" &&
        loanApplication.status !== "CANCELED" &&
        loanApplication.status !== "EXPIRED" &&
        loanApplication.loanStatus !== "PAID_OFF"
      ) {
        loan = await getLoanApplication(true)
        todoItems = await getTodoItems()
        getLoanRef.current = setInterval(async () => {
          loan = await getLoanApplication(true)

          if (
            !!loan &&
            (loan.status === "REJECTED" ||
              loan.status === "CANCELED" ||
              loan.status === "EXPIRED" ||
              loan.loanStatus === "PAID_OFF")
          ) {
            clearInterval(getLoanRef.current)
          }

          if (loan === false) {
            clearInterval(getLoanRef.current)
          }
        }, 5000)
      }

      getTodoItemsRef.current = setInterval(async () => {
        todoItems = await getTodoItems()

        if (todoItems === false) {
          clearInterval(getTodoItemsRef.current)
        }
      }, 5000)
    }

    getLoanData()

    return () => {
      clearInterval(getLoanRef.current)
      clearInterval(getTodoItemsRef.current)
    }
  }, [])

  if (!loanApplication) return null

  if (activeItemId !== null)
    return (
      <UploadFiles
        activeItemId={activeItemId}
        setActiveItemId={setActiveItemId}
      />
    )

  if (trackGuarantor)
    return (
      <header className="header pt-8 pb-4 mh-100vh">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mx-auto text-left">
              <div className="card">
                <div className="card-body py-0 px-0 py-md-0">
                  <GuarantorStatus loanApplication={loanApplication} />
                  <button
                    type="button"
                    className="btn btn-xl btn-secondary mx-auto"
                    data-dismiss="modal"
                    onClick={() => setTrackGuarantor(null)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    )

  if (signAgreement)
    return (
      <SignAgreement
        setSignAgreement={setSignAgreement}
        guarantor={guarantor}
      />
    )

  if (reviewBorrowerLoanAgreement)
    return (
      <ReviewBorrowerLoanAgreement
        setReviewBorrowerLoanAgreement={setReviewBorrowerLoanAgreement}
      />
    )

  return (
    <header className="header pt-8 pb-4 mh-100vh">
      <div className="container">
        <div className="row">
          <div className="col-11 col-sm-8 col-md-6 mx-auto text-left">
            <div className="card">
              <div className="card-body py-0 px-0 pb-5">
                {loanApplication.status === "REJECTED" ||
                loanApplication.status === "CANCELED" ||
                loanApplication.status === "EXPIRED" ? (
                  <h4 className="fw-400 w-100 my-3">
                    Thank you for applying, {loanApplication.firstName}!
                  </h4>
                ) : (
                  <h4 className="fw-400 w-100 my-3">
                    Welcome {loanApplication.firstName}!
                  </h4>
                )}

                {!guarantor &&
                  loanApplication.status === "APPROVED" &&
                  loanApplication.loanStatus === "CREATED" && (
                    <SummaryActiveLoan />
                  )}

                {!guarantor &&
                  loanApplication.status === "APPROVED" &&
                  (loanApplication.loanStatus === "FUNDED" ||
                    loanApplication.loanStatus === "LATE" ||
                    loanApplication.loanStatus === "ACCELERATED") && (
                    <SummaryActiveLoanFunded />
                  )}

                {!guarantor &&
                  loanApplication.status === "REJECTED" &&
                  Difference_In_Days < 30 && (
                    <div
                      className="bg-white"
                      style={{
                        position: "fixed",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        zIndex: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div className="col-11 text-center" style={{ flex: "0" }}>
                        <h3>
                          Thank you for applying, {loanApplication.firstName}!
                        </h3>
                        <hr />
                        <p className="lead-2">
                          <h5 className="mb-0 fw-600">
                            Unfortunately, we can't offer you a personal loan at
                            this time.
                          </h5>{" "}
                          A Notice of Adverse Action will be sent to your email
                          address on file. We encourage you to re-apply for a
                          loan from Trustic after {retryLoanDate.getMonth() + 1}
                          /{retryLoanDate.getDate()}/
                          {retryLoanDate.getFullYear()}
                        </p>
                        <p>
                          Please, visit our <Link to="/hub">Q&A Hub</Link> if
                          any questions.
                        </p>
                      </div>
                    </div>
                  )}

                {guarantor &&
                  loanApplication.status === "APPROVED" &&
                  loanApplication.loanStatus === "CREATED" && (
                    <SummaryActiveGuarantor />
                  )}

                {guarantor &&
                  loanApplication.status === "APPROVED" &&
                  (loanApplication.loanStatus === "FUNDED" ||
                    loanApplication.loanStatus === "LATE" ||
                    loanApplication.loanStatus === "ACCELERATED") && (
                    <SummaryActiveGuarantorFunded />
                  )}

                {guarantor &&
                  loanApplication.status === "REJECTED" &&
                  Difference_In_Days < 7 && (
                    <>
                      {/* <div className="alert alert-primary mb-4 p-2"> */}
                      <h5 className="fw-300 m-0">
                        We are sorry, you did not meet our guarantor criteria.
                      </h5>
                      <p className="lead-1">
                        <h5 className="mt-4 mb-2 fw-400">
                          Unfortunately, we are unable to accept you as a
                          guarantor for{" "}
                          {loanApplication && loanApplication.borrowerFirstName}
                          ! For more information about this decision, please,
                          check a Notice of Adverse action that has been sent to
                          your email address.
                        </h5>
                        If you are currently a guarantor on an existing loan
                        with us, please, do not worry as this will not impact
                        the existing loan in any way.
                        <br />
                        Please, note that this decision will not impact your
                        credit score, as only a soft credit inquiry has been
                        generated that is visible only to you.
                        <h5 className="mt-4 mb-2 fw-400">What's next?</h5>
                        In order to finalize their loan application,{" "}
                        {loanApplication &&
                          loanApplication.borrowerFirstName}{" "}
                        would have to find another guarantor.
                        <h5 className="mt-4 mb-2 fw-400">
                          Is there a new guarantor in mind?
                        </h5>
                        They should be able to complete their own guarantor
                        application online at:{" "}
                        {isBrowser && window.location.href}.
                        <h5 className="mt-4 mb-2 fw-400">
                          Can I apply for a loan myself?
                        </h5>
                        Yes, our credit policy for Guarantors is different. Even
                        if we couldn't accept you as a Guarantor, you might
                        qualify for a loan from Trustic directly. Keep in mind
                        though, that you will still need to find a Guarantor to
                        support your loan application.
                      </p>
                      <div className="card border shadow">
                        <div className="card-body">
                          <h5 className="card-title">Apply for a new loan</h5>
                          <p className="lead-1">
                            Borrowing with a guarantor is a cost affective way
                            to manage your credit. Borrow between $1,100 and
                            $3,000, with APR under 35.99%.
                          </p>
                          <div
                            className="dropdown mx-auto"
                            style={{ maxWidth: "250px", width: "100%" }}
                          >
                            <button
                              id="moneyReason"
                              className="btn btn-lg btn-rounded btn-light dropdown-toggle w-100"
                              data-toggle="dropdown"
                              style={{
                                zIndex: 2,
                              }}
                            >
                              What's the money for?
                            </button>
                            <p
                              id="loan-purpose-message"
                              className="mb-0"
                              style={{ display: "none" }}
                            >
                              <small className="text-danger">
                                Please select a loan purpose!
                              </small>
                            </p>
                            <div
                              className="dropdown-menu w-100"
                              style={{
                                zIndex: 2,
                              }}
                            >
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => {
                                  document.body.click()
                                  document.getElementById(
                                    "loan-purpose-message"
                                  ).style.display = "none"
                                  localStorage.setItem(
                                    "moneyReason",
                                    "Pay for unexpected expense"
                                  )
                                  document.getElementById(
                                    "moneyReason"
                                  ).innerText = localStorage.getItem(
                                    "moneyReason"
                                  )

                                  dataLayer.push({
                                    money_reason_main:
                                      "Pay for unexpected expense",
                                  })
                                }}
                              >
                                Pay for unexpected expense
                              </a>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => {
                                  document.body.click()
                                  document.getElementById(
                                    "loan-purpose-message"
                                  ).style.display = "none"
                                  localStorage.setItem(
                                    "moneyReason",
                                    "Cover medical expenses"
                                  )
                                  document.getElementById(
                                    "moneyReason"
                                  ).innerText = localStorage.getItem(
                                    "moneyReason"
                                  )

                                  dataLayer.push({
                                    money_reason_main: "Cover medical expenses",
                                  })
                                }}
                              >
                                Cover medical expenses
                              </a>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => {
                                  document.body.click()
                                  document.getElementById(
                                    "loan-purpose-message"
                                  ).style.display = "none"
                                  localStorage.setItem(
                                    "moneyReason",
                                    "Cover educational expenses"
                                  )
                                  document.getElementById(
                                    "moneyReason"
                                  ).innerText = localStorage.getItem(
                                    "moneyReason"
                                  )

                                  dataLayer.push({
                                    money_reason_main:
                                      "Cover educational expenses",
                                  })
                                }}
                              >
                                Cover educational expenses
                              </a>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => {
                                  document.body.click()
                                  document.getElementById(
                                    "loan-purpose-message"
                                  ).style.display = "none"
                                  localStorage.setItem(
                                    "moneyReason",
                                    "Pay for home improvement"
                                  )
                                  document.getElementById(
                                    "moneyReason"
                                  ).innerText = localStorage.getItem(
                                    "moneyReason"
                                  )

                                  dataLayer.push({
                                    money_reason_main:
                                      "Pay for home improvement",
                                  })
                                }}
                              >
                                Pay for home improvement{" "}
                              </a>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => {
                                  document.body.click()
                                  document.getElementById(
                                    "loan-purpose-message"
                                  ).style.display = "none"
                                  localStorage.setItem(
                                    "moneyReason",
                                    "Pay for car repair"
                                  )
                                  document.getElementById(
                                    "moneyReason"
                                  ).innerText = localStorage.getItem(
                                    "moneyReason"
                                  )

                                  dataLayer.push({
                                    money_reason_main: "Pay for car repair",
                                  })
                                }}
                              >
                                Pay for car repair
                              </a>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => {
                                  document.body.click()
                                  document.getElementById(
                                    "loan-purpose-message"
                                  ).style.display = "none"
                                  localStorage.setItem(
                                    "moneyReason",
                                    "Pay off expensive debt"
                                  )
                                  document.getElementById(
                                    "moneyReason"
                                  ).innerText = localStorage.getItem(
                                    "moneyReason"
                                  )

                                  dataLayer.push({
                                    money_reason_main: "Pay off expensive debt",
                                  })
                                }}
                              >
                                Pay off expensive debt
                              </a>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => {
                                  document.body.click()
                                  document.getElementById(
                                    "loan-purpose-message"
                                  ).style.display = "none"
                                  localStorage.setItem(
                                    "moneyReason",
                                    "Pay for a vacation"
                                  )
                                  document.getElementById(
                                    "moneyReason"
                                  ).innerText = localStorage.getItem(
                                    "moneyReason"
                                  )

                                  dataLayer.push({
                                    money_reason_main: "Pay for a vacation",
                                  })
                                }}
                              >
                                Pay for a vacation
                              </a>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => {
                                  document.body.click()
                                  document.getElementById(
                                    "loan-purpose-message"
                                  ).style.display = "none"
                                  localStorage.setItem(
                                    "moneyReason",
                                    "Buy a new car"
                                  )
                                  document.getElementById(
                                    "moneyReason"
                                  ).innerText = localStorage.getItem(
                                    "moneyReason"
                                  )

                                  dataLayer.push({
                                    money_reason_main: "Buy a new car",
                                  })
                                }}
                              >
                                Buy a new car
                              </a>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => {
                                  document.body.click()
                                  document.getElementById(
                                    "loan-purpose-message"
                                  ).style.display = "none"
                                  localStorage.setItem(
                                    "moneyReason",
                                    "Pay to move or relocate"
                                  )
                                  document.getElementById(
                                    "moneyReason"
                                  ).innerText = localStorage.getItem(
                                    "moneyReason"
                                  )

                                  dataLayer.push({
                                    money_reason_main:
                                      "Pay to move or relocate",
                                  })
                                }}
                              >
                                Pay to move or relocate
                              </a>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => {
                                  document.body.click()
                                  document.getElementById(
                                    "loan-purpose-message"
                                  ).style.display = "none"
                                  localStorage.setItem("moneyReason", "Other")
                                  document.getElementById(
                                    "moneyReason"
                                  ).innerText = localStorage.getItem(
                                    "moneyReason"
                                  )

                                  dataLayer.push({
                                    money_reason_main: "Other",
                                  })
                                }}
                              >
                                Other
                              </a>
                            </div>
                          </div>
                          <a
                            href="/apply"
                            className="btn-block mx-auto btn btn-xl btn-rounded btn-danger w-100 mt-4 fs-16"
                            style={{ maxWidth: "250px", width: "100%" }}
                            onClick={e => {
                              e.preventDefault()
                              dataLayer.push({ event: "check_your_rate_main" })
                              if (localStorage.getItem("moneyReason")) {
                                initLoan()
                              } else {
                                document.getElementById(
                                  "loan-purpose-message"
                                ).style.display = "block"
                              }
                            }}
                          >
                            {stepLoading ? (
                              <div
                                className="loader"
                                style={{
                                  borderColor: "rgb(255 255 255 / 20%)",
                                  borderLeftColor: "#fff",
                                  fontSize: "2.4px",
                                }}
                              />
                            ) : (
                              "Apply Now"
                            )}
                          </a>
                          <p className="mt-4">
                            <b>Watch:</b>{" "}
                            <a
                              href="https://youtu.be/Ze7RWbQfS1M"
                              target="_blank"
                            >
                              How to have a discussion with your guarantor
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="card border shadow mt-4">
                        <div className="card-body">
                          <h5 className="card-title">Questions?</h5>
                          <a
                            href="/hub"
                            target="_blank"
                            className="btn-block mx-auto btn btn-xl brn-rounded btn-dark w-100"
                            style={{ maxWidth: "250px", width: "100%" }}
                          >
                            Visit our Q&A Hub
                          </a>
                        </div>
                      </div>
                    </>
                  )}

                {(loanApplication.status === "CONDITIONAL_APPROVED" ||
                  loanApplication.status === "CONTRACT_SIGNED") && (
                  <>
                    {guarantor ? (
                      <SummaryIdleGuarantor
                        setSignAgreement={setSignAgreement}
                        setReviewBorrowerLoanAgreement={
                          setReviewBorrowerLoanAgreement
                        }
                      />
                    ) : (
                      <SummaryIdleLoan
                        setSignAgreement={setSignAgreement}
                        setTrackGuarantor={setTrackGuarantor}
                      />
                    )}
                  </>
                )}

                {/* {(loanApplication.loanStatus !== "FUNDED" &&
                  loanApplication.status !== "REJECTED") || */}
                {(loanApplication.status === "APPROVED" &&
                  (loanApplication.loanStatus === "FUNDED" ||
                    loanApplication.loanStatus === "LATE" ||
                    loanApplication.loanStatus === "ACCELERATED")) ||
                loanApplication.status === "CONDITIONAL_APPROVED" ||
                loanApplication.status === "CONTRACT_SIGNED" ||
                (todoItems?.length && loanApplication.status !== "RELEASED") ? (
                  <>
                    {loanApplication.status !== "APPROVED" &&
                      loanApplication.loanStatus !== "FUNDED" && (
                        <p className="lead-1">
                          Meanwhile, please, check your TO DO list below.
                        </p>
                      )}
                    <h4 className="fw-300">TO DO</h4>
                  </>
                ) : null}

                {/* ACTIONS */}

                {loanApplication.status === "APPROVED" &&
                  (loanApplication.loanStatus === "FUNDED" ||
                    loanApplication.loanStatus === "LATE" ||
                    loanApplication.loanStatus === "ACCELERATED") && (
                    <>
                      {todoItems &&
                        todoItems.map((item, key) => (
                          <Fragment key={key}>
                            <hr
                              className="mt-3 mb-3"
                              style={{ borderColor: "#000" }}
                            />
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="my-0 pr-5">
                                {item.summary}
                                <i
                                  className="fa fa-info-circle"
                                  aria-hidden="true"
                                  style={{
                                    marginLeft: "4px",
                                  }}
                                  onClick={e => {
                                    e.stopPropagation()
                                    setShowTermsModal(true)
                                    setTermsModalContent({
                                      title: item.infoDetailsTitle,
                                      hidePrint: true,
                                      lastUpdate: null,
                                      text: (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item.infoDetails,
                                          }}
                                        />
                                      ),
                                    })
                                  }}
                                ></i>
                              </p>
                              {item.type === "SECURE_FILE_UPLOAD" ? (
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-dark btn-block px-0"
                                    style={{
                                      minWidth: "92px",
                                    }}
                                    onClick={e => {
                                      e.stopPropagation()
                                      setActiveItemId(key)
                                      document.body.scrollTop = 0
                                      document.documentElement.scrollTop = 0
                                    }}
                                  >
                                    Upload
                                  </button>
                                </div>
                              ) : item.type ===
                                "BANK_ACCOUNT_UPDATE_GUARANTOR" ? (
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-dark btn-block px-0"
                                    style={{
                                      minWidth: "92px",
                                    }}
                                    onClick={e => {
                                      e.stopPropagation()
                                      document.body.scrollTop = 0
                                      document.documentElement.scrollTop = 0
                                      if (
                                        item.type ===
                                        "BANK_ACCOUNT_UPDATE_GUARANTOR"
                                      ) {
                                        window.location.href =
                                          "/apply/update-bank-account-guarantor"
                                      }
                                    }}
                                  >
                                    UPDATE
                                  </button>
                                </div>
                              ) : item.type ===
                                "BANK_ACCOUNT_UPDATE_BORROWER" ? (
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-dark btn-block px-0"
                                    style={{
                                      minWidth: "92px",
                                    }}
                                    onClick={e => {
                                      e.stopPropagation()
                                      document.body.scrollTop = 0
                                      document.documentElement.scrollTop = 0
                                      if (
                                        item.type ===
                                        "BANK_ACCOUNT_UPDATE_BORROWER"
                                      ) {
                                        window.location.href =
                                          "/apply/update-bank-account-borrower"
                                      }
                                    }}
                                  >
                                    UPDATE
                                  </button>
                                </div>
                              ) : item.type === "PLAID_LOGIN" ? (
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-dark btn-block px-0"
                                    style={{
                                      minWidth: "92px",
                                    }}
                                    onClick={e => {
                                      e.stopPropagation()
                                      playdLogin()
                                    }}
                                  >
                                    CONNECT
                                  </button>
                                </div>
                              ) : item.type === "PAYLIANCE_PAYMENT" ? (
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-dark btn-block px-0"
                                    style={{
                                      minWidth: "92px",
                                    }}
                                    onClick={e => {
                                      e.stopPropagation()
                                      document.body.scrollTop = 0
                                      document.documentElement.scrollTop = 0
                                      window.location.href = "/payment"
                                    }}
                                  >
                                    Pay
                                  </button>
                                </div>
                              ) : (
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-dark btn-block px-0"
                                    style={{
                                      minWidth: "92px",
                                    }}
                                    onClick={e => {
                                      e.stopPropagation()
                                      document.body.scrollTop = 0
                                      document.documentElement.scrollTop = 0
                                      if (item.type === "BANK_ACCOUNT_UPDATE") {
                                        window.location.href =
                                          "/apply/update-bank-account"
                                      }
                                    }}
                                  >
                                    Open
                                  </button>
                                </div>
                              )}
                            </div>
                            <hr
                              className="mt-3 mb-3"
                              style={{ borderColor: "#000" }}
                            />
                          </Fragment>
                        ))}
                    </>
                  )}

                {!guarantor &&
                  loanApplication.status === "APPROVED" &&
                  (loanApplication.loanStatus === "FUNDED" ||
                    loanApplication.loanStatus === "LATE" ||
                    loanApplication.loanStatus === "ACCELERATED") && (
                    <>
                      <hr
                        className="mt-3 mb-3"
                        style={{ borderColor: "#000" }}
                      />
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="my-0">See your loan agreement</p>
                        <div>
                          <button
                            type="button"
                            className="btn btn-lg btn-dark btn-block px-0"
                            style={{
                              minWidth: "92px",
                            }}
                            onClick={() => {
                              document.body.scrollTop = 0
                              document.documentElement.scrollTop = 0
                              setSignAgreement(true)
                            }}
                          >
                            View
                          </button>
                        </div>
                      </div>
                      <hr
                        className="mt-3 mb-3"
                        style={{
                          borderColor: "#000",
                        }}
                      />
                    </>
                  )}

                {guarantor &&
                  loanApplication.status === "APPROVED" &&
                  (loanApplication.loanStatus === "FUNDED" ||
                    loanApplication.loanStatus === "LATE" ||
                    loanApplication.loanStatus === "ACCELERATED") && (
                    <>
                      {loanApplication.selected && (
                        <>
                          <hr
                            className="mt-3 mb-3"
                            style={{ borderColor: "#000" }}
                          />
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="my-0">
                              {loanApplication.borrowerFirstName}'s loan
                              agreement.
                            </p>
                            <div>
                              <button
                                type="button"
                                className="btn btn-lg btn-dark btn-block px-0"
                                style={{
                                  minWidth: "92px",
                                }}
                                onClick={() => {
                                  document.body.scrollTop = 0
                                  document.documentElement.scrollTop = 0
                                  setReviewBorrowerLoanAgreement(true)
                                }}
                              >
                                View
                              </button>
                            </div>
                          </div>
                          <hr
                            className="mt-3 mb-3"
                            style={{
                              borderColor: "#000",
                            }}
                          />
                        </>
                      )}
                      {loanApplication.contractStatus && (
                        <>
                          <hr
                            className="mt-3 mb-3"
                            style={{ borderColor: "#000" }}
                          />
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="my-0">
                              Your personal guarantee obligation.
                            </p>
                            <div>
                              <button
                                type="button"
                                className="btn btn-lg btn-dark btn-block px-0"
                                style={{
                                  minWidth: "92px",
                                }}
                                onClick={() => {
                                  document.body.scrollTop = 0
                                  document.documentElement.scrollTop = 0
                                  setSignAgreement(true)
                                }}
                              >
                                View
                              </button>
                            </div>
                          </div>
                          <hr
                            className="mt-3 mb-3"
                            style={{
                              borderColor: "#000",
                            }}
                          />
                        </>
                      )}
                    </>
                  )}

                {(loanApplication.status === "CONDITIONAL_APPROVED" ||
                  loanApplication.status === "CONTRACT_SIGNED") &&
                  guarantor && (
                    <>
                      {loanApplication.selected &&
                        loanApplication.loanApplication.contractStatus && (
                          <>
                            <hr
                              className="mt-3 mb-3"
                              style={{ borderColor: "#000" }}
                            />
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="my-0">
                                {loanApplication.borrowerContractAck
                                  ? "View"
                                  : "Review"}{" "}
                                {loanApplication.borrowerFirstName}'s loan
                                agreement.
                              </p>
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-lg btn-dark btn-block px-0"
                                  style={{
                                    minWidth: "92px",
                                  }}
                                  onClick={() => {
                                    document.body.scrollTop = 0
                                    document.documentElement.scrollTop = 0
                                    setReviewBorrowerLoanAgreement(true)
                                  }}
                                >
                                  {loanApplication.borrowerContractAck
                                    ? "View"
                                    : "Review"}
                                </button>
                              </div>
                            </div>
                            <hr
                              className="mt-3 mb-3"
                              style={{
                                borderColor: "#000",
                              }}
                            />
                          </>
                        )}
                      {loanApplication.contractStatus && (
                        <>
                          <hr
                            className="mt-3 mb-3"
                            style={{ borderColor: "#000" }}
                          />
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="my-0">
                              Sign your personal guarantee agreement.
                            </p>
                            <div>
                              <button
                                type="button"
                                className="btn btn-lg btn-dark btn-block px-0"
                                style={{
                                  minWidth: "92px",
                                }}
                                onClick={() => {
                                  document.body.scrollTop = 0
                                  document.documentElement.scrollTop = 0
                                  setSignAgreement(true)
                                }}
                              >
                                {loanApplication.contractStatus === "SIGNED"
                                  ? "View"
                                  : "Sign"}
                              </button>
                            </div>
                          </div>
                          <hr
                            className="mt-3 mb-3"
                            style={{
                              borderColor: "#000",
                            }}
                          />
                        </>
                      )}
                    </>
                  )}

                {(loanApplication.status === "CONDITIONAL_APPROVED" ||
                  loanApplication.status === "CONTRACT_SIGNED") &&
                  !guarantor && (
                    <>
                      {loanApplication.productType !== "PERSONAL_LOAN" && (
                        <>
                          <p className="mb-0">
                            Share application link with your Guarantor <br />
                            {loanApplication && (
                              <CopyToClipboard
                                text={`${domain}/apply/guarantor/${loanApplication.businessId}`}
                                onCopy={() => {
                                  setCopied(true)
                                  setTimeout(() => setCopied(false), 3000)
                                }}
                              >
                                <a
                                  href={`${domain}/apply/guarantor/${loanApplication.businessId}`}
                                  target="_blank"
                                  onClick={e => e.preventDefault()}
                                  className="btn btn-lg btn-outline-dark btn-block px-0 mt-2 d-flex justify-center"
                                  style={{
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                  }}
                                >
                                  <small
                                    style={{
                                      maxWidth: "90%",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "clip",
                                      display: "block",
                                    }}
                                  >
                                    {copied
                                      ? "Link Copied"
                                      : `${domain}/apply/guarantor/
                                      ${loanApplication.businessId}`}
                                  </small>
                                  {!copied && (
                                    <i
                                      className="fa fa-clone"
                                      aria-hidden="true"
                                      style={{
                                        marginLeft: "4px",
                                      }}
                                    ></i>
                                  )}
                                </a>
                              </CopyToClipboard>
                            )}
                          </p>
                          <hr
                            className="mt-3 mb-3"
                            style={{ borderColor: "#000" }}
                          />
                        </>
                      )}
                      {loanApplication.productType !== "PERSONAL_LOAN" && (
                        <>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="my-0">
                              Track progress of your Guarantor
                            </p>
                            <div>
                              <button
                                type="button"
                                className="btn btn-lg btn-dark btn-block px-0"
                                style={{
                                  minWidth: "92px",
                                }}
                                onClick={() => {
                                  document.body.scrollTop = 0
                                  document.documentElement.scrollTop = 0
                                  setTrackGuarantor(true)
                                }}
                              >
                                Track
                              </button>
                            </div>
                          </div>
                          <hr
                            className="mt-3 mb-3"
                            style={{
                              borderColor: "#000",
                            }}
                          />
                        </>
                      )}

                      {loanApplication.contractStatus && (
                        <>
                          <hr
                            className="mt-3 mb-3"
                            style={{ borderColor: "#000" }}
                          />
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="my-0">Sign Your Loan Agreement</p>
                            <div>
                              <button
                                type="button"
                                className="btn btn-lg btn-dark btn-block px-0"
                                style={{
                                  minWidth: "92px",
                                }}
                                onClick={() => {
                                  document.body.scrollTop = 0
                                  document.documentElement.scrollTop = 0
                                  setSignAgreement(true)
                                }}
                              >
                                {loanApplication.contractStatus === "SIGNED"
                                  ? "View"
                                  : "Sign"}
                              </button>
                            </div>
                          </div>
                          <hr
                            className="mt-3 mb-3"
                            style={{
                              borderColor: "#000",
                            }}
                          />
                        </>
                      )}
                    </>
                  )}

                {loanApplication.status !== "APPROVED" &&
                  loanApplication.loanStatus !== "FUNDED" && (
                    <>
                      {todoItems &&
                        todoItems.map((item, key) => (
                          <Fragment key={key}>
                            <hr
                              className="mt-3 mb-3"
                              style={{ borderColor: "#000" }}
                            />
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="my-0">
                                {item.summary}
                                <i
                                  className="fa fa-info-circle"
                                  aria-hidden="true"
                                  style={{
                                    marginLeft: "4px",
                                  }}
                                  onClick={e => {
                                    e.stopPropagation()
                                    setShowTermsModal(true)
                                    setTermsModalContent({
                                      title: item.infoDetailsTitle,
                                      hidePrint: true,
                                      lastUpdate: null,
                                      text: (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item.infoDetails,
                                          }}
                                        />
                                      ),
                                    })
                                  }}
                                ></i>
                              </p>
                              {item.type === "SECURE_FILE_UPLOAD" ? (
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-dark btn-block px-0"
                                    style={{
                                      minWidth: "92px",
                                    }}
                                    onClick={e => {
                                      e.stopPropagation()
                                      setActiveItemId(key)
                                      document.body.scrollTop = 0
                                      document.documentElement.scrollTop = 0
                                    }}
                                  >
                                    Upload
                                  </button>
                                </div>
                              ) : item.type ===
                                "BANK_ACCOUNT_UPDATE_GUARANTOR" ? (
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-dark btn-block px-0"
                                    style={{
                                      minWidth: "92px",
                                    }}
                                    onClick={e => {
                                      e.stopPropagation()
                                      document.body.scrollTop = 0
                                      document.documentElement.scrollTop = 0
                                      if (
                                        item.type ===
                                        "BANK_ACCOUNT_UPDATE_GUARANTOR"
                                      ) {
                                        window.location.href =
                                          "/apply/update-bank-account-guarantor"
                                      }
                                    }}
                                  >
                                    UPDATE
                                  </button>
                                </div>
                              ) : item.type ===
                                "BANK_ACCOUNT_UPDATE_BORROWER" ? (
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-dark btn-block px-0"
                                    style={{
                                      minWidth: "92px",
                                    }}
                                    onClick={e => {
                                      e.stopPropagation()
                                      document.body.scrollTop = 0
                                      document.documentElement.scrollTop = 0
                                      if (
                                        item.type ===
                                        "BANK_ACCOUNT_UPDATE_BORROWER"
                                      ) {
                                        window.location.href =
                                          "/apply/update-bank-account-borrower"
                                      }
                                    }}
                                  >
                                    UPDATE
                                  </button>
                                </div>
                              ) : item.type === "DISBURSEMENT_ACCOUNT" ? (
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-dark btn-block px-0"
                                    style={{
                                      minWidth: "92px",
                                    }}
                                    onClick={e => {
                                      e.stopPropagation()
                                      document.body.scrollTop = 0
                                      document.documentElement.scrollTop = 0
                                      if (
                                        item.type === "DISBURSEMENT_ACCOUNT"
                                      ) {
                                        window.location.href =
                                          "/apply/add-disbursement-bank-account"
                                      }
                                    }}
                                  >
                                    ADD
                                  </button>
                                </div>
                              ) : item.type === "PAYLIANCE_PAYMENT" ? (
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-dark btn-block px-0"
                                    style={{
                                      minWidth: "92px",
                                    }}
                                    onClick={e => {
                                      e.stopPropagation()
                                      document.body.scrollTop = 0
                                      document.documentElement.scrollTop = 0
                                      window.location.href = "/payment"
                                    }}
                                  >
                                    Pay
                                  </button>
                                </div>
                              ) : (
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-dark btn-block px-0"
                                    style={{
                                      minWidth: "92px",
                                    }}
                                    onClick={e => {
                                      e.stopPropagation()
                                      document.body.scrollTop = 0
                                      document.documentElement.scrollTop = 0
                                      if (item.type === "BANK_ACCOUNT_UPDATE") {
                                        window.location.href =
                                          "/apply/update-bank-account"
                                      }
                                    }}
                                  >
                                    Open
                                  </button>
                                </div>
                              )}
                            </div>
                            <hr
                              className="mt-3 mb-3"
                              style={{
                                borderColor: "#000",
                              }}
                            />
                          </Fragment>
                        ))}
                    </>
                  )}

                {((!guarantor &&
                  loanApplication.status === "REJECTED" &&
                  Difference_In_Days > 30) ||
                  (guarantor &&
                    loanApplication.status === "REJECTED" &&
                    Difference_In_Days > 7) ||
                  loanApplication.status === "CANCELED" ||
                  loanApplication.status === "EXPIRED" ||
                  loanApplication.status === "RELEASED" ||
                  loanApplication.loanStatus === "PAID_OFF") && (
                  <>
                    <h4 className="fw-300">What would you like to do today?</h4>
                    <div className="card border shadow">
                      <div className="card-body">
                        <h5 className="card-title">Apply for a new loan</h5>
                        <p className="lead-1">
                          Borrowing with a guarantor is a cost affective way to
                          manage your credit. Borrow between $1,100 and $3,000,
                          with APR under 35.99%.
                        </p>
                        <div
                          className="dropdown mx-auto"
                          style={{ maxWidth: "250px", width: "100%" }}
                        >
                          <button
                            id="moneyReason"
                            className="btn btn-lg btn-rounded btn-light dropdown-toggle w-100"
                            data-toggle="dropdown"
                            style={{
                              zIndex: 2,
                            }}
                          >
                            What's the money for?
                          </button>
                          <p
                            id="loan-purpose-message"
                            className="mb-0"
                            style={{ display: "none" }}
                          >
                            <small className="text-danger">
                              Please select a loan purpose!
                            </small>
                          </p>
                          <div
                            className="dropdown-menu w-100"
                            style={{
                              zIndex: 2,
                            }}
                          >
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                document.body.click()
                                document.getElementById(
                                  "loan-purpose-message"
                                ).style.display = "none"
                                localStorage.setItem(
                                  "moneyReason",
                                  "Pay for unexpected expense"
                                )
                                document.getElementById(
                                  "moneyReason"
                                ).innerText = localStorage.getItem(
                                  "moneyReason"
                                )

                                dataLayer.push({
                                  money_reason_main:
                                    "Pay for unexpected expense",
                                })
                              }}
                            >
                              Pay for unexpected expense
                            </a>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                document.body.click()
                                document.getElementById(
                                  "loan-purpose-message"
                                ).style.display = "none"
                                localStorage.setItem(
                                  "moneyReason",
                                  "Cover medical expenses"
                                )
                                document.getElementById(
                                  "moneyReason"
                                ).innerText = localStorage.getItem(
                                  "moneyReason"
                                )

                                dataLayer.push({
                                  money_reason_main: "Cover medical expenses",
                                })
                              }}
                            >
                              Cover medical expenses
                            </a>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                document.body.click()
                                document.getElementById(
                                  "loan-purpose-message"
                                ).style.display = "none"
                                localStorage.setItem(
                                  "moneyReason",
                                  "Cover educational expenses"
                                )
                                document.getElementById(
                                  "moneyReason"
                                ).innerText = localStorage.getItem(
                                  "moneyReason"
                                )

                                dataLayer.push({
                                  money_reason_main:
                                    "Cover educational expenses",
                                })
                              }}
                            >
                              Cover educational expenses
                            </a>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                document.body.click()
                                document.getElementById(
                                  "loan-purpose-message"
                                ).style.display = "none"
                                localStorage.setItem(
                                  "moneyReason",
                                  "Pay for home improvement"
                                )
                                document.getElementById(
                                  "moneyReason"
                                ).innerText = localStorage.getItem(
                                  "moneyReason"
                                )

                                dataLayer.push({
                                  money_reason_main: "Pay for home improvement",
                                })
                              }}
                            >
                              Pay for home improvement{" "}
                            </a>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                document.body.click()
                                document.getElementById(
                                  "loan-purpose-message"
                                ).style.display = "none"
                                localStorage.setItem(
                                  "moneyReason",
                                  "Pay for car repair"
                                )
                                document.getElementById(
                                  "moneyReason"
                                ).innerText = localStorage.getItem(
                                  "moneyReason"
                                )

                                dataLayer.push({
                                  money_reason_main: "Pay for car repair",
                                })
                              }}
                            >
                              Pay for car repair
                            </a>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                document.body.click()
                                document.getElementById(
                                  "loan-purpose-message"
                                ).style.display = "none"
                                localStorage.setItem(
                                  "moneyReason",
                                  "Pay off expensive debt"
                                )
                                document.getElementById(
                                  "moneyReason"
                                ).innerText = localStorage.getItem(
                                  "moneyReason"
                                )

                                dataLayer.push({
                                  money_reason_main: "Pay off expensive debt",
                                })
                              }}
                            >
                              Pay off expensive debt
                            </a>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                document.body.click()
                                document.getElementById(
                                  "loan-purpose-message"
                                ).style.display = "none"
                                localStorage.setItem(
                                  "moneyReason",
                                  "Pay for a vacation"
                                )
                                document.getElementById(
                                  "moneyReason"
                                ).innerText = localStorage.getItem(
                                  "moneyReason"
                                )

                                dataLayer.push({
                                  money_reason_main: "Pay for a vacation",
                                })
                              }}
                            >
                              Pay for a vacation
                            </a>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                document.body.click()
                                document.getElementById(
                                  "loan-purpose-message"
                                ).style.display = "none"
                                localStorage.setItem(
                                  "moneyReason",
                                  "Buy a new car"
                                )
                                document.getElementById(
                                  "moneyReason"
                                ).innerText = localStorage.getItem(
                                  "moneyReason"
                                )

                                dataLayer.push({
                                  money_reason_main: "Buy a new car",
                                })
                              }}
                            >
                              Buy a new car
                            </a>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                document.body.click()
                                document.getElementById(
                                  "loan-purpose-message"
                                ).style.display = "none"
                                localStorage.setItem(
                                  "moneyReason",
                                  "Pay to move or relocate"
                                )
                                document.getElementById(
                                  "moneyReason"
                                ).innerText = localStorage.getItem(
                                  "moneyReason"
                                )

                                dataLayer.push({
                                  money_reason_main: "Pay to move or relocate",
                                })
                              }}
                            >
                              Pay to move or relocate
                            </a>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                document.body.click()
                                document.getElementById(
                                  "loan-purpose-message"
                                ).style.display = "none"
                                localStorage.setItem("moneyReason", "Other")
                                document.getElementById(
                                  "moneyReason"
                                ).innerText = localStorage.getItem(
                                  "moneyReason"
                                )

                                dataLayer.push({
                                  money_reason_main: "Other",
                                })
                              }}
                            >
                              Other
                            </a>
                          </div>
                        </div>
                        <a
                          href="/apply"
                          className="btn-block mx-auto btn btn-xl btn-rounded btn-danger w-100 mt-4 fs-16"
                          style={{ maxWidth: "250px", width: "100%" }}
                          onClick={e => {
                            e.preventDefault()
                            dataLayer.push({ event: "check_your_rate_main" })
                            if (localStorage.getItem("moneyReason")) {
                              initLoan()
                            } else {
                              document.getElementById(
                                "loan-purpose-message"
                              ).style.display = "block"
                            }
                          }}
                        >
                          {stepLoading ? (
                            <div
                              className="loader"
                              style={{
                                borderColor: "rgb(255 255 255 / 20%)",
                                borderLeftColor: "#fff",
                                fontSize: "2.4px",
                              }}
                            />
                          ) : (
                            "Apply Now"
                          )}
                        </a>
                        <p className="mt-4">
                          <b>Watch:</b>{" "}
                          <a
                            href="https://youtu.be/Ze7RWbQfS1M"
                            target="_blank"
                          >
                            How to have a discussion with your guarantor
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="card border shadow mt-4">
                      <div className="card-body">
                        <h5 className="card-title">Questions?</h5>
                        <a
                          href="/hub"
                          target="_blank"
                          className="btn btn-xl brn-rounded btn-dark w-100"
                        >
                          Visit our Q&A Hub
                        </a>
                      </div>
                    </div>
                    <div className="card border shadow mt-4">
                      <div className="card-body">
                        <h5 className="card-title">Contact Support</h5>
                        <p className="lead-1">
                          <b>Email:</b>{" "}
                          <a href="mailto:support@trustic.com" target="_blank">
                            support@trustic.com
                          </a>
                        </p>
                        <p className="lead-1">
                          <b>Call/Text:</b> (888) 429-0480
                        </p>
                        <p className="lead-1">
                          <b>Monday - Friday:</b> 7am - 5pm EST
                        </p>
                      </div>
                    </div>
                  </>
                )}

                {(loanApplication.loanStatus === "FUNDED" ||
                  loanApplication.loanStatus === "LATE" ||
                  loanApplication.loanStatus === "ACCELERATED") &&
                  loanApplication.status !== "RELEASED" && (
                    <>
                      <h4 className="fw-300 mt-5">Customer Service</h4>
                      <p>
                        <b>Email:</b>{" "}
                        <a href="mailto:support@trustic.com" target="_blank">
                          support@trustic.com
                        </a>
                      </p>
                      <p>
                        <b>Call:</b> (888) 429-0480
                      </p>
                      <p>
                        <b>Monday - Friday:</b>
                        <br />
                        7:00am - 5pm Central
                      </p>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Summary
