import React, { useState } from "react"
import { navigate } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import FormikField from "./formik-field"
import { ApplyProvider, useApplyContext } from "./context"
import { REST } from "./api-calls-map"
import Modal from "./modal"

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email required."),
})

const ResetPassword = () => {
  const { API_MAP, stepLoading, setStepLoading } = useApplyContext()

  const breakpoints = useBreakpoint()

  return (
    <div
      style={{
        minHeight: breakpoints.md
          ? "calc(100vh - 141px)"
          : "calc(100vh - 69px)",
      }}
    >
      <header className="header pt-9 pb-6">
        <div className="container">
          <div className="bg-white rounded shadow-7 w-400 mw-100 p-6 m-auto">
            <h5 className="mb-5 text-center">Reset password</h5>
            <p className="text-center">
              Please, enter the email associated with your Trustic account to
              receive your new password.
            </p>
            <Formik
              initialValues={{
                email: "",
              }}
              onSubmit={async values => {
                const ModalInstance = Modal.mixin({
                  customClass: {
                    confirmButton: "btn btn-dark btn-xl fw-600 w-40",
                    cancelButton: "btn btn-danger btn-xl fw-600 w-40",
                  },
                  buttonsStyling: false,
                })

                try {
                  setStepLoading(true)

                  const apiResponse = await fetch(API_MAP.resetPassword, {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      text: values.email,
                    }),
                  })

                  const jsonRes = await apiResponse.json()

                  if (apiResponse.status === 400) {
                    ModalInstance.fire({
                      title:
                        "<strong style='text-transform: uppercase'>Hmm...</strong>",
                      text:
                        "We don’t recognize this email address. Please, make sure to use the email address that you provided during your registration with Trustic. Still having issues? Please, email us at support@trustic.com or call/text to 888-429-0480.",
                      confirmButtonText: "Ok",
                    }).then(result => {
                      if (result.isConfirmed) {
                        document.body.scrollTop = 0
                        document.documentElement.scrollTop = 0
                      }
                    })
                  }

                  if (apiResponse.status === 200) {
                    ModalInstance.fire({
                      title:
                        "<strong style='text-transform: uppercase'></strong>",
                      text:
                        "A new password has been sent to the email you provided.",
                      confirmButtonText: "Log in",
                    }).then(result => {
                      if (result.isConfirmed) {
                        navigate("/apply/login")
                      }
                    })
                  }
                  setStepLoading(false)
                } catch (error) {
                  return ModalInstance.fire({
                    icon: "error",
                    title:
                      "<strong style='text-transform: uppercase'>Oops...</strong>",
                    text: "Something went wrong. Please try again later.",
                    confirmButtonText: "Ok",
                  }).then(() => {
                    setStepLoading(false)
                  })
                }
              }}
              validationSchema={ResetPasswordSchema}
            >
              {formikProps => (
                <Form>
                  <FormikField type="text" name="email" placeholder="Email" />
                  <div className="form-group">
                    <button
                      className="btn btn-xl btn-block btn-dark"
                      type="submit"
                      disabled={!formikProps.isValid}
                    >
                      {stepLoading ? (
                        <div
                          className="loader"
                          style={{
                            borderColor: "rgb(255 255 255 / 20%)",
                            borderLeftColor: "#fff",
                            fontSize: "2.4px",
                          }}
                        />
                      ) : (
                        "Reset"
                      )}
                    </button>
                    <p className="mt-6 text-center">
                      Having trouble? Email{" "}
                      <a href="mailto:support@trustic.com" target="_blank">
                        support@trustic.com
                      </a>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </header>
    </div>
  )
}

const ResetPasswordHOC = () => (
  <ApplyProvider>
    <ResetPassword />
  </ApplyProvider>
)

export default ResetPasswordHOC
