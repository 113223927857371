import React from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import "yup-phone"
import { useApplyContext } from "./context"
import StepHeader from "./step-header"
import FormikField from "./formik-field"

Yup.addMethod(Yup.string, "dateOfBirth", function () {
  return this.test(`test-date-of-birth`, "", function (value) {
    const { path, createError } = this

    if (!!value) {
      let date = value.split("-")

      const month = parseInt(date[0])

      const day = parseInt(date[1])

      const year = parseInt(date[2])

      if (month < 1 || month > 12) {
        return createError({ path, message: "Please enter a valid month." })
      }

      if (day < 1 || day > 31) {
        return createError({ path, message: "Please enter a valid day." })
      }

      if (year < 1920 || year > 2005) {
        return createError({ path, message: "Please enter a valid year." })
      }
    }

    return value
  })
})

const PersonalInfoSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name required."),
  lastName: Yup.string().required("Last Name required."),
  dob: Yup.string().dateOfBirth().required("Date of Birth is required."),
})

/*global dataLayer */
const PersonalInfo = ({ guarantor }) => {
  const {
    API_MAP,
    stepLoading,
    activeStep,
    formState,
    submitStepAndUpdateAppState,
    isNotFirstApplication,
  } = useApplyContext()

  const activeStepState = formState[activeStep]

  return (
    <header className="header pt-8 pb-4 mh-100vh">
      <div className="container">
        <div className="row">
          <div className="col-md-7 mx-auto text-left">
            <div className="card">
              <div className="card-body py-0 px-0 py-md-0">
                <StepHeader
                  activeStep={activeStep}
                  title="Tell us a bit about you."
                  description={
                    guarantor
                      ? "We need this to find your credit report. This won’t affect your credit score*."
                      : "We need this to find your credit report, which helps us calculate your rate. Checking your rate won't affect your credit score*."
                  }
                />
                <Formik
                  enableReinitialize
                  initialValues={activeStepState}
                  onSubmit={values => {
                    submitStepAndUpdateAppState(
                      guarantor
                        ? API_MAP.updateGuarantorApplication
                        : API_MAP.loanApplication,
                      { ...values, submitAction: false }
                    )
                    if (!guarantor) {
                      dataLayer.push({ event: "borrower_step2_submitted" })
                      dataLayer.push({
                        borrower_birth_year: values.dob.split("-")[2],
                      })
                    }
                  }}
                  validationSchema={PersonalInfoSchema}
                >
                  {formikProps => (
                    <Form>
                      <div className="row no-gap mb-5">
                        <div className="col-md-12">
                          <FormikField
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            disabled={
                              isNotFirstApplication && activeStepState.firstName
                            }
                          />
                          <FormikField
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            disabled={
                              isNotFirstApplication && activeStepState.lastName
                            }
                          />
                          <lable className="mb-1" style={{ display: "block" }}>
                            Date of Birth
                          </lable>
                          <FormikField
                            type="dob"
                            name="dob"
                            placeholder="MM-DD-YYYY"
                            disabled={
                              isNotFirstApplication && activeStepState.dob
                            }
                          />
                        </div>
                        <div className="row col-8 offset-2">
                          <div className="col-12">
                            <button
                              type="submit"
                              className="btn btn-lg btn-dark btn-block"
                              disabled={!formikProps.isValid}
                            >
                              {stepLoading ? (
                                <div
                                  className="loader"
                                  style={{
                                    borderColor: "rgb(255 255 255 / 20%)",
                                    borderLeftColor: "#fff",
                                    fontSize: "2.4px",
                                  }}
                                />
                              ) : (
                                "Next"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                <p>
                  {guarantor ? (
                    <small>
                      * Getting approved as Guarantor generates a soft credit
                      inquiry, which is visible only to you.
                    </small>
                  ) : (
                    <small>
                      * Checking your rate generates a soft credit inquiry,
                      which is visible only to you. A hard credit inquiry that
                      may affect your credit score only appears when your loan
                      is funded.
                    </small>
                  )}
                  <br />
                  {guarantor ? (
                    <>
                      <p
                        className="lead-1 mt-2 mb-0"
                        style={{ textTransform: "capitalize" }}
                      >
                        WHAT YOU NEED TO KNOW ABOUT BEING A GUARANTOR ON TRUSTIC
                        LOAN
                      </p>
                      <ul className="mb-0 pl-5 py-2 lead-1">
                        <li>
                          To help the government fight the funding of terrorism
                          and money laundering activities, federal law requires
                          all financial institutions to obtain, verify, and
                          record information that identifies each person who
                          opens an account.
                        </li>
                        <li>
                          When you open an account, we will ask for your name,
                          date of birth, address and other information that will
                          allow us to identify you.
                        </li>
                        <li>
                          As a Guarantor, you will have to submit your own
                          application online and sign a personal guarantee
                          agreement.
                        </li>
                        <li>
                          Once the loan is finalized, the loan proceeds will be
                          disbursed to your bank account so that you can give
                          the funds to the Borrower.
                        </li>
                        <li>
                          You should not act as a Guarantor for someone you
                          don’t know. Remember that it will be your
                          responsibility to step in if the Borrower cannot make
                          the loan payments.
                        </li>
                      </ul>
                    </>
                  ) : (
                    <>
                      <p
                        className="lead-1 mt-2 mb-0"
                        style={{ textTransform: "capitalize" }}
                      >
                        What you need to know about applying for a loan with
                        Trustic:
                      </p>
                      <ul className="mb-0 pl-5 py-2 lead-1">
                        <li>
                          To help the government fight the funding of terrorism
                          and money laundering activities, federal law requires
                          all financial institutions to obtain, verify, and
                          record information that identifies each person who
                          opens an account.
                        </li>
                        <li>
                          When you open an account, we will ask for your name,
                          date of birth, address and other information that will
                          allow us to identify you.
                        </li>
                        <li>
                          As part of the application, you will have to nominate
                          a Guarantor. A Guarantor can be a family member or a
                          friend who will provide a personal guarantee for your
                          loan.
                        </li>
                        <li>
                          Your Guarantor will have to submit their own
                          application online and sign a personal guarantee
                          agreement. You will receive a guarantor application
                          link that you can share with your Guarantor to your
                          email address after you choose your loan offer.
                        </li>
                        <li>
                          Once the loan is finalised, the loan proceeds will be
                          disbursed to your Guarantor who will act as your
                          agent. You should not take this loan unless you trust
                          your Guarantor to forward the loan proceeds to you.
                        </li>
                      </ul>
                    </>
                  )}
                  <p className="lead-1">
                    Unless otherwise specified, all loans are provided by
                    Nextate Credit, LLC, NMLS ID 2050326.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default PersonalInfo
