import React, { useState, useRef, useEffect } from "react"
import produce from "immer"
import { useApplyContext } from "./context"
import StepHeader from "./step-header"
import Modal from "./modal"

const FileComponent = ({ file, index, removeFile }) => {
  const [fileDataUrl, setFileDataUrl] = useState(null)

  useEffect(() => {
    const getFileDataUrl = async () => {
      let fileUrl = await new Promise(resolve => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(file)
      })

      setFileDataUrl(fileUrl)
    }

    getFileDataUrl()
  }, [])

  return fileDataUrl ? (
    <div className="col-6 mb-5">
      <div
        className="card text-white bg-img"
        style={
          file.type.includes("image")
            ? {
                backgroundImage: `url(${fileDataUrl})`,
              }
            : { backgroundColor: "#000" }
        }
        data-overlay="5"
      >
        <div
          className="card-body"
          style={{ minHeight: "200px", position: "relative" }}
        >
          {/* <h5 className="card-title">{file.size}</h5> */}
          <p
            style={{
              whiteSpace: "pre",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {file.name}
          </p>
          <button
            type="button"
            className="btn btn-xs btn-round btn-danger"
            style={{
              position: "absolute",
              right: "1.25rem",
              bottom: "1.25rem",
            }}
            onClick={() => removeFile(index)}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="col-6 mb-5">
      <div
        className="card text-white bg-img"
        style={{ backgroundColor: "#000" }}
        data-overlay="5"
      >
        <div
          className="card-body d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px" }}
        >
          <div
            className="loader"
            style={{
              borderColor: "rgb(255 255 255 / 20%)",
              borderLeftColor: "#fff",
              fontSize: "3.4px",
            }}
          />
        </div>
      </div>
    </div>
  )
}

const UploadFiles = ({ activeItemId, setActiveItemId }) => {
  const {
    API_MAP,
    stepLoading,
    todoItems,
    setShowTermsModal,
    setTermsModalContent,
    getLoanApplication,
  } = useApplyContext()

  const fileInputRef = useRef()

  const [uploadLoading, setUploadLoading] = useState(false)

  const [files, setFiles] = useState([])

  const removeFile = index => {
    const filesStateClone = produce(files, draft => {
      draft.splice(index, 1)
    })

    setFiles(filesStateClone)
  }

  const handleFileUpload = async (event, id) => {
    const targetFiles = event.target.files

    if (files.length > 10) {
      return Modal.mixin({
        customClass: {
          confirmButton: "btn btn-dark btn-xl fw-600 w-40",
          cancelButton: "btn btn-danger btn-xl fw-600 w-40",
        },
        buttonsStyling: false,
        backdrop: "#f1f1f1",
      }).fire({
        title: "<strong style='text-transform: uppercase'>Hmm...</strong>",
        text: "Please select a maximum of 10 files.",
        confirmButtonText: "Ok",
      })
    }

    const filesStateClone = produce(files, draft => {
      draft.push(...Array.from(targetFiles))
    })

    setFiles(filesStateClone)
  }

  const uploadMedia = async () => {
    let loan = await getLoanApplication()

    if (!loan) {
      return false
    }

    setUploadLoading(true)

    const formData = new FormData()

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i])
    }

    formData.append("todoItemId", todoItems[activeItemId].id)

    const apiResponse = await fetch(API_MAP.uploadFiles, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      body: formData,
    })

    const status = apiResponse.status

    if (status === 200) {
      setUploadLoading(false)
      Modal.mixin({
        customClass: {
          confirmButton: "btn btn-dark btn-xl fw-600 w-40",
        },
        buttonsStyling: false,
        backdrop: "#f1f1f1",
      })
        .fire({
          title:
            "<strong style='text-transform: uppercase'>Congratulations</strong>",
          text: "Files were successfully uploaded.",
          confirmButtonText: "Ok",
        })
        .then(result => {
          // if (result.isConfirmed) {
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
          setActiveItemId(null)
          // }
        })
    }
  }

  useEffect(() => {
    setShowTermsModal(true)
    setTermsModalContent({
      title:
        todoItems &&
        todoItems[activeItemId] &&
        todoItems[activeItemId].infoDetailsTitle,
      hidePrint: true,
      lastUpdate: null,
      closeLabel: "Ok",
      text: (
        <div
          dangerouslySetInnerHTML={{
            __html:
              todoItems &&
              todoItems[activeItemId] &&
              todoItems[activeItemId].infoDetails,
          }}
        />
      ),
    })
  }, [])

  return (
    <header className="header pt-8 pb-4 mh-100vh">
      <div className="container">
        <div className="row">
          <div className="col-md-7 mx-auto text-left">
            <div className="card">
              <div className="card-body py-0 px-0 py-md-0">
                <StepHeader
                  title={
                    todoItems &&
                    todoItems[activeItemId] &&
                    todoItems[activeItemId].summary
                  }
                />
                <div className="row">
                  {files.map((file, index) => (
                    <FileComponent
                      file={file}
                      index={index}
                      key={index}
                      removeFile={removeFile}
                    />
                  ))}
                </div>
                <div className="row no-gap mb-5">
                  <div className="col-12 d-flex align-items-center justify-content-between">
                    <div className="w-35">
                      <input
                        ref={fileInputRef}
                        onChange={event =>
                          handleFileUpload(event, activeItemId)
                        }
                        type="file"
                        style={{ display: "none" }}
                        multiple
                      />
                      <button
                        type="button"
                        className="btn btn-label btn-lg btn-dark pl-7"
                        onClick={() => fileInputRef.current.click()}
                      >
                        <label>
                          <i
                            className="fa fa-cloud-upload"
                            aria-hidden="true"
                          ></i>
                        </label>
                        Add Files
                      </button>
                    </div>
                    <div className="w-65">
                      <p className="text-dark fw-600 mb-0">
                        {files.length} Files uploaded
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row col-12 mx-0 px-0">
                  <div className="col-2 px-0">
                    <button
                      type="button"
                      className="btn btn-lg btn-dark btn-block px-0"
                      onClick={() => setActiveItemId(null)}
                    >
                      <span className="ti-angle-left"></span>
                    </button>
                  </div>
                  <div className="col-10 pr-0">
                    <button
                      type="submit"
                      className="btn btn-lg btn-dark btn-block"
                      onClick={() => uploadMedia()}
                      disabled={!files.length}
                    >
                      {uploadLoading ? (
                        <div
                          className="loader"
                          style={{
                            borderColor: "rgb(255 255 255 / 20%)",
                            borderLeftColor: "#fff",
                            fontSize: "2.4px",
                          }}
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default UploadFiles
