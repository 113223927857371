import React, { useState, useEffect } from "react"
import { withPrefix } from "gatsby"
import ReactTooltip from "react-tooltip"
import produce from "immer"
import { REST } from "./api-calls-map"
import { useApplyContext } from "./context"
import StepHeader from "./step-header"

/*global dataLayer */
const LoanOffer = ({ guarantor }) => {
  const {
    API_MAP,
    stepLoading,
    activeStep,
    formState,
    setFormState,
    submitStepAndUpdateAppState,
    setShowTermsModal,
    setTermsModalContent,
    setAppLoading,
    setAppLoadingStatus,
  } = useApplyContext()

  const activeStepState = formState[activeStep]

  useEffect(() => {
    if (!guarantor && activeStepState && activeStepState.businessId) {
      dataLayer.push({
        event: "borrower_offer_business_id",
      })
      dataLayer.push({
        borrower_offer_business_id: activeStepState.businessId,
      })
    }
  }, [activeStepState])

  const aprIcon = (
    <i
      className="fa fa-info-circle"
      aria-hidden="true"
      style={{
        marginLeft: "4px",
      }}
      onClick={() => {
        setShowTermsModal(true)
        setTermsModalContent({
          title: "APR",
          hidePrint: true,
          lastUpdate: null,
          text: (
            <p className="lead-2">
              APR stands for Annual Percentage Rate and measures the total cost
              of credit annually. Your APR consists of your annual interest rate
              plus a one-time origination fee.
            </p>
          ),
        })
      }}
    ></i>
  )

  const originationFeeIcon = (
    <i
      className="fa fa-info-circle"
      aria-hidden="true"
      style={{
        marginLeft: "4px",
      }}
      onClick={() => {
        setShowTermsModal(true)
        setTermsModalContent({
          title: "ORIGINATION FEE",
          hidePrint: true,
          lastUpdate: null,
          text: (
            <p className="lead-2">
              We charge a one-time origination fee of 5% to process your loan
              application.
            </p>
          ),
        })
      }}
    ></i>
  )

  const cashToYouIcon = (
    <i
      className="fa fa-info-circle"
      aria-hidden="true"
      style={{
        marginLeft: "4px",
      }}
      onClick={() => {
        setShowTermsModal(true)
        setTermsModalContent({
          title: "Cash to You",
          hidePrint: true,
          lastUpdate: null,
          text:
            selectedOffering === 0 ? (
              <p className="lead-2">
                The proceeds of this loan will be disbursed directly to your
                Guarantor as your agent. You should not take this loan unless
                you trust your Guarantor to forward the loan proceeds to you.
              </p>
            ) : (
              <p className="lead-2">
                The proceeds of this loan will be disbursed directly to your
                bank account provided during the application.
              </p>
            ),
        })
      }}
    ></i>
  )

  const [offerLoading, setOfferLoading] = useState(false)

  const [selectedOffering, setSelectedOffering] = useState(0)

  const getNewOffer = async neg => {
    if (!window.loanAmount) {
      window.loanAmount = activeStepState.loanAmount
    }

    setOfferLoading(true)
    const [res, status] = await REST({
      url: `${API_MAP.newSimulateOffer}`,
      method: "POST",
      body: {
        loanAmount: neg
          ? activeStepState[0].loanAmount - 50
          : activeStepState[0].loanAmount + 50,
      },
    })

    if (status === 200) {
      const formStateClone = produce(formState, draft => {
        draft[activeStep] = res
      })

      setFormState(formStateClone)
      setOfferLoading(false)
    }
  }

  if (activeStepState && guarantor) {
    return (
      <header className="header pt-8 pb-4 mh-100vh">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mx-auto text-left">
              <div className="card">
                <div className="card-body py-0 px-0 py-md-0">
                  <ReactTooltip
                    place={"right"}
                    multiline={true}
                    overridePosition={() => ({ left: 20 })}
                  />

                  {activeStepState.selected && (
                    <>
                      <h2 className="mb-3 display-5 text-center">
                        Congratulations!
                      </h2>
                      <p className="lead-2 mb-5">
                        You have been conditionally approved to be a Guarantor
                        for {activeStepState.borrowerFirstName}.
                        <br />
                        Please, finalize your application so that the loan can
                        be disbursed.
                      </p>
                      <p className="lead-2 mb-5 text-center">
                        {activeStepState.borrowerFirstName}'s loan details:
                      </p>
                      <div className="row no-gap mb-5 mb-5">
                        <div className="col-md-12">
                          <div className="card card-body border pb-0 mb-5">
                            <ul className="list-unstyled mb-6">
                              <li className="d-flex justify-content-between">
                                <h5 className="mb-0">Months:</h5>{" "}
                                {activeStepState.borrowerOffer &&
                                  activeStepState.borrowerOffer
                                    ?.loanTermMonths &&
                                  activeStepState.borrowerOffer?.loanTermMonths}
                              </li>
                              <hr className="my-4" />
                              <li className="d-flex justify-content-between">
                                <h5 className="mb-0">Per Month:</h5> $
                                {activeStepState.borrowerOffer &&
                                  activeStepState.borrowerOffer
                                    .monthlyPayment &&
                                  activeStepState.borrowerOffer.monthlyPayment.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                              </li>
                              <hr className="my-4" />
                              <li className="d-flex justify-content-between">
                                <h5 className="mb-0">Interest:</h5>{" "}
                                {activeStepState.borrowerOffer &&
                                  activeStepState.borrowerOffer
                                    .baseInterestRate &&
                                  activeStepState.borrowerOffer.baseInterestRate.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                %
                              </li>
                              <hr className="my-4" />
                              <li className="d-flex justify-content-between">
                                <h5
                                  className="mb-0 fw-800"
                                  style={{
                                    textDecoration: "underline",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  APR
                                  {aprIcon}
                                </h5>
                                <b>
                                  {activeStepState.borrowerOffer &&
                                    activeStepState.borrowerOffer.apr &&
                                    activeStepState.borrowerOffer.apr.toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  %
                                </b>
                              </li>
                              <hr className="my-4" />
                              <li className="d-flex justify-content-between">
                                <h5 className="mb-0">Loan:</h5> $
                                {activeStepState.borrowerOffer &&
                                  activeStepState.borrowerOffer.loanAmount &&
                                  activeStepState.borrowerOffer.loanAmount.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                              </li>
                              <hr className="my-4" />
                              <li className="d-flex justify-content-between">
                                <h5
                                  className="mb-0 fw-800"
                                  style={{
                                    textDecoration: "underline",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Origination Fee
                                  {originationFeeIcon}
                                </h5>
                                <b>
                                  $
                                  {activeStepState.borrowerOffer &&
                                    activeStepState.borrowerOffer
                                      .originationFee &&
                                    activeStepState.borrowerOffer.originationFee.toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                </b>
                              </li>
                              <hr className="my-4" />
                              <li className="d-flex justify-content-between">
                                <h5
                                  className="mb-0 fw-800"
                                  style={{
                                    textDecoration: "underline",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Cash to You
                                  {cashToYouIcon}
                                </h5>
                                <b>
                                  $
                                  {activeStepState.borrowerOffer &&
                                    activeStepState.borrowerOffer
                                      .disbursementAmount &&
                                    activeStepState.borrowerOffer.disbursementAmount.toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                </b>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row col-8 offset-2">
                          <div className="col-md-12">
                            <button
                              type="button"
                              className="btn btn-lg btn-dark btn-block"
                              onClick={() => {
                                submitStepAndUpdateAppState(activeStepState)
                              }}
                            >
                              {stepLoading ? (
                                <div
                                  className="loader"
                                  style={{
                                    borderColor: "rgb(255 255 255 / 20%)",
                                    borderLeftColor: "#fff",
                                    fontSize: "2.4px",
                                  }}
                                />
                              ) : (
                                "Continue"
                              )}
                            </button>
                          </div>
                        </div>
                        {(!guarantor ||
                          (guarantor && activeStepState.selected)) && (
                          <code className="mt-4 text-center">
                            <p>
                              All loan offers are contingent on further
                              verifications, including additional bank account,
                              income and identity checks.
                            </p>
                          </code>
                        )}
                      </div>
                    </>
                  )}

                  {!activeStepState.selected && activeStepState.borrowerOffer && (
                    <>
                      <h2 className="mb-3 display-5 text-center">
                        Congratulations!
                      </h2>
                      <p className="lead-2 mb-5">
                        You have been conditionally approved to be a Guarantor
                        for {activeStepState.borrowerFirstName}.
                        <br />
                        We will notify you by email regarding the next steps
                        once {activeStepState.borrowerFirstName} is ready to
                        proceed.
                      </p>
                      <p className="lead-2 mb-5 text-center">
                        {activeStepState.borrowerFirstName}'s loan details:
                      </p>
                      <div className="row no-gap mb-5 mb-5">
                        <div className="col-md-12">
                          <div className="card card-body border pb-0 mb-5">
                            <ul className="list-unstyled mb-6">
                              <li className="d-flex justify-content-between">
                                <h5 className="mb-0">Months:</h5>{" "}
                                {activeStepState.borrowerOffer &&
                                  activeStepState.borrowerOffer
                                    ?.loanTermMonths &&
                                  activeStepState.borrowerOffer?.loanTermMonths}
                              </li>
                              <hr className="my-4" />
                              <li className="d-flex justify-content-between">
                                <h5 className="mb-0">Per Month:</h5> $
                                {activeStepState.borrowerOffer &&
                                  activeStepState.borrowerOffer
                                    .monthlyPayment &&
                                  activeStepState.borrowerOffer.monthlyPayment.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                              </li>
                              <hr className="my-4" />
                              <li className="d-flex justify-content-between">
                                <h5 className="mb-0">Interest:</h5>{" "}
                                {activeStepState.borrowerOffer &&
                                  activeStepState.borrowerOffer
                                    .baseInterestRate &&
                                  activeStepState.borrowerOffer.baseInterestRate.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                %
                              </li>
                              <hr className="my-4" />
                              <li className="d-flex justify-content-between">
                                <h5
                                  className="mb-0 fw-800"
                                  style={{
                                    textDecoration: "underline",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  APR
                                  {aprIcon}
                                </h5>
                                <b>
                                  {activeStepState.borrowerOffer &&
                                    activeStepState.borrowerOffer.apr &&
                                    activeStepState.borrowerOffer.apr.toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  %
                                </b>
                              </li>
                              <hr className="my-4" />
                              <li className="d-flex justify-content-between">
                                <h5 className="mb-0">Loan:</h5> $
                                {activeStepState.borrowerOffer &&
                                  activeStepState.borrowerOffer.loanAmount &&
                                  activeStepState.borrowerOffer.loanAmount.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                              </li>
                              <hr className="my-4" />
                              <li className="d-flex justify-content-between">
                                <h5
                                  className="mb-0 fw-800"
                                  style={{
                                    textDecoration: "underline",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Origination Fee
                                  {originationFeeIcon}
                                </h5>
                                <b>
                                  $
                                  {activeStepState.borrowerOffer &&
                                    activeStepState.borrowerOffer
                                      .originationFee &&
                                    activeStepState.borrowerOffer.originationFee.toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                </b>
                              </li>
                              <hr className="my-4" />
                              <li className="d-flex justify-content-between">
                                <h5
                                  className="mb-0 fw-800"
                                  style={{
                                    textDecoration: "underline",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Cash to You
                                  {cashToYouIcon}
                                </h5>
                                <b>
                                  $
                                  {activeStepState.borrowerOffer &&
                                    activeStepState.borrowerOffer
                                      .disbursementAmount &&
                                    activeStepState.borrowerOffer.disbursementAmount.toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                </b>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }

  return activeStepState && !!Object.keys(activeStepState || {}).length ? (
    <header className="header pt-8 pb-4 mh-100vh">
      <div className="container">
        <div className="row">
          <div className="col-md-7 mx-auto text-left">
            <div className="card">
              <div className="card-body py-0 px-0 py-md-0">
                <ReactTooltip place={"right"} multiline={true} />
                <StepHeader
                  activeStep={activeStep}
                  title={`Choose an amount up to $${
                    activeStepState &&
                    activeStepState[0]?.maxLoanAmount &&
                    activeStepState[0]?.maxLoanAmount.toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  }.`}
                  description={
                    <>
                      Just a few steps left to finalize your application.
                      Please, choose the loan offer below. Save on interest
                      payments by choosing Guarantor Personal Loan.
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        style={{
                          marginLeft: "4px",
                        }}
                        onClick={() => {
                          setShowTermsModal(true)
                          setTermsModalContent({
                            title: "What is Guarantor?",
                            hidePrint: true,
                            lastUpdate: null,
                            text: (
                              <p className="lead-2">
                                A Guarantor is a friend or family member who
                                will be responsible for this loan in the event
                                you cannot meet your loan obligations. Please,
                                share the Guarantor application link with your
                                prospective Guarantor.
                              </p>
                            ),
                          })
                        }}
                      ></i>
                    </>
                  }
                />
                <div className="row no-gap mb-5 mb-5">
                  <div className="col-md-12">
                    <div className="card card-body border pb-0 mb-5">
                      <div className="col-md-12 px-0 d-flex justify-content-center align-items-center mt-2 mb-4">
                        <button
                          type="button"
                          className="btn btn-square btn-success btn-sm"
                          disabled={
                            activeStepState[0]?.loanAmount <=
                            activeStepState[0]?.minLoanAmount
                          }
                          onClick={() => {
                            getNewOffer(true)
                          }}
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                        <span
                          className="badge text-dark fw-600 ml-3 mr-3"
                          style={{
                            fontSize: "26px",
                          }}
                        >
                          $
                          {activeStepState[0]?.loanAmount &&
                            activeStepState[0].loanAmount.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }
                            )}
                        </span>
                        <button
                          type="button"
                          className="btn btn-square btn-success btn-sm"
                          disabled={
                            activeStepState[0]?.loanAmount >=
                            activeStepState[0]?.maxLoanAmount
                          }
                          onClick={() => {
                            getNewOffer()
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>
                      {/* <hr className="my-4" /> */}
                      <table
                        class="table mt-4 mb-4 offerings-table"
                        style={{ border: 0 }}
                      >
                        {activeStepState.length > 1 && (
                          <thead>
                            <tr>
                              <th></th>
                              <th
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "top",
                                }}
                                className={`${
                                  selectedOffering === 0 &&
                                  activeStepState.length > 1 &&
                                  "active-pricing-option-th"
                                }`}
                                onClick={() => setSelectedOffering(0)}
                              >
                                <h6 className="mb-0 fw-800">
                                  Guarantor Personal Loan
                                </h6>
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "top",
                                  display:
                                    activeStepState.length <= 1 ? "none" : "",
                                }}
                                className={`${
                                  selectedOffering === 1 &&
                                  "active-pricing-option-th"
                                }`}
                                onClick={() => setSelectedOffering(1)}
                              >
                                <h6 className="mb-0 fw-800">Personal Loan</h6>
                              </th>
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          <tr>
                            <td style={{ paddingLeft: 0 }}>
                              <h6 className="mb-0">Loan Amount:</h6>
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              className={`${
                                selectedOffering === 0 &&
                                activeStepState.length > 1 &&
                                "active-pricing-option-td"
                              }`}
                              onClick={() => setSelectedOffering(0)}
                            >
                              $
                              {activeStepState[0]?.loanAmount &&
                                activeStepState[0]?.loanAmount.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                display:
                                  activeStepState.length <= 1 ? "none" : "",
                              }}
                              className={`${
                                selectedOffering === 1 &&
                                "active-pricing-option-td"
                              }`}
                              onClick={() => setSelectedOffering(1)}
                            >
                              $
                              {activeStepState[1]?.loanAmount &&
                                activeStepState[1]?.loanAmount.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingLeft: 0 }}>
                              <h6 className="mb-0 fw-800">Monthly Payment:</h6>
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              className={`${
                                selectedOffering === 0 &&
                                activeStepState.length > 1 &&
                                "active-pricing-option-td"
                              }`}
                              onClick={() => setSelectedOffering(0)}
                            >
                              <b>
                                $
                                {activeStepState[0]?.monthlyPayment &&
                                  activeStepState[0]?.monthlyPayment.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                              </b>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                display:
                                  activeStepState.length <= 1 ? "none" : "",
                              }}
                              className={`${
                                selectedOffering === 1 &&
                                "active-pricing-option-td"
                              }`}
                              onClick={() => setSelectedOffering(1)}
                            >
                              <b>
                                $
                                {activeStepState[1]?.monthlyPayment &&
                                  activeStepState[1]?.monthlyPayment.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingLeft: 0 }}>
                              <h6
                                className="mb-0 fw-800"
                                style={{
                                  textDecoration: "underline",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                APR
                                {aprIcon}
                              </h6>
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              className={`${
                                selectedOffering === 0 &&
                                activeStepState.length > 1 &&
                                "active-pricing-option-td"
                              }`}
                              onClick={() => setSelectedOffering(0)}
                            >
                              <b>
                                {activeStepState[0]?.apr &&
                                  activeStepState[0]?.apr.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                %
                              </b>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                display:
                                  activeStepState.length <= 1 ? "none" : "",
                              }}
                              className={`${
                                selectedOffering === 1 &&
                                "active-pricing-option-td"
                              }`}
                              onClick={() => setSelectedOffering(1)}
                            >
                              <b>
                                {activeStepState[1]?.apr &&
                                  activeStepState[1]?.apr.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                %
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingLeft: 0 }}>
                              <h6 className="mb-0">Base Interest:</h6>
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              className={`${
                                selectedOffering === 0 &&
                                activeStepState.length > 1 &&
                                "active-pricing-option-td"
                              }`}
                              onClick={() => setSelectedOffering(0)}
                            >
                              {activeStepState[0]?.baseInterestRate &&
                                activeStepState[0]?.baseInterestRate.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              %
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                display:
                                  activeStepState.length <= 1 ? "none" : "",
                              }}
                              className={`${
                                selectedOffering === 1 &&
                                "active-pricing-option-td"
                              }`}
                              onClick={() => setSelectedOffering(1)}
                            >
                              {activeStepState[1]?.baseInterestRate &&
                                activeStepState[1]?.baseInterestRate.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingLeft: 0 }}>
                              <h6 className="mb-0">Months:</h6>
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              className={`${
                                selectedOffering === 0 &&
                                activeStepState.length > 1 &&
                                "active-pricing-option-td"
                              }`}
                              onClick={() => setSelectedOffering(0)}
                            >
                              {activeStepState[0]?.loanTermMonths &&
                                activeStepState[0]?.loanTermMonths}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                display:
                                  activeStepState.length <= 1 ? "none" : "",
                              }}
                              className={`${
                                selectedOffering === 1 &&
                                "active-pricing-option-td"
                              }`}
                              onClick={() => setSelectedOffering(1)}
                            >
                              {activeStepState[1]?.loanTermMonths &&
                                activeStepState[1]?.loanTermMonths}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingLeft: 0 }}>
                              <h6
                                className="mb-0 fw-800"
                                style={{
                                  textDecoration: "underline",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Origination Fee
                                {originationFeeIcon}
                              </h6>
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              className={`${
                                selectedOffering === 0 &&
                                activeStepState.length > 1 &&
                                "active-pricing-option-td"
                              }`}
                              onClick={() => setSelectedOffering(0)}
                            >
                              <b>
                                $
                                {activeStepState[0]?.originationFee &&
                                  activeStepState[0]?.originationFee.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                              </b>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                display:
                                  activeStepState.length <= 1 ? "none" : "",
                              }}
                              className={`${
                                selectedOffering === 1 &&
                                "active-pricing-option-td"
                              }`}
                              onClick={() => setSelectedOffering(1)}
                            >
                              <b>
                                $
                                {activeStepState[1]?.originationFee &&
                                  activeStepState[1]?.originationFee.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingLeft: 0 }}>
                              <h6
                                className="mb-0 fw-800"
                                style={{
                                  textDecoration: "underline",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Cash to You
                                {cashToYouIcon}
                              </h6>
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              className={`${
                                selectedOffering === 0 &&
                                activeStepState.length > 1 &&
                                "active-pricing-option-td"
                              }`}
                              onClick={() => setSelectedOffering(0)}
                            >
                              <b>
                                $
                                {activeStepState[0]?.disbursementAmount &&
                                  activeStepState[0]?.disbursementAmount.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                              </b>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                display:
                                  activeStepState.length <= 1 ? "none" : "",
                              }}
                              className={`${
                                selectedOffering === 1 &&
                                "active-pricing-option-td"
                              }`}
                              onClick={() => setSelectedOffering(1)}
                            >
                              <b>
                                $
                                {activeStepState[1]?.disbursementAmount &&
                                  activeStepState[1]?.disbursementAmount.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingLeft: 0 }}>
                              <h6
                                className="mb-0 fw-800"
                                style={{
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Guarantor Required
                              </h6>
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              className={`${
                                selectedOffering === 0 &&
                                activeStepState.length > 1 &&
                                "active-pricing-option-td"
                              }`}
                              onClick={() => setSelectedOffering(0)}
                            >
                              <b>Yes</b>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                display:
                                  activeStepState.length <= 1 ? "none" : "",
                              }}
                              className={`${
                                selectedOffering === 1 &&
                                "active-pricing-option-td"
                              }`}
                              onClick={() => setSelectedOffering(1)}
                            >
                              <b>No</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {offerLoading ? (
                        <div
                          className="bg-white"
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            zIndex: 2,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div className="loader"></div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row col-8 offset-2">
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn btn-lg btn-dark btn-block"
                        onClick={() => {
                          setAppLoading(true)
                          setAppLoadingStatus("  ")
                          submitStepAndUpdateAppState(
                            `${API_MAP.acceptLoanOffer}/${activeStepState[selectedOffering].businessId}`,
                            {
                              loanAmount:
                                activeStepState[selectedOffering].loanAmount,
                            }
                          )
                          if (!guarantor) {
                            dataLayer.push({ event: "borrower_offer_selected" })
                          }
                        }}
                      >
                        {stepLoading ? (
                          <div
                            className="loader"
                            style={{
                              borderColor: "rgb(255 255 255 / 20%)",
                              borderLeftColor: "#fff",
                              fontSize: "2.4px",
                            }}
                          />
                        ) : (
                          "Continue"
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="col-4 d-flex flex-column justify-content-center align-items-center mt-6">
                    <img
                      style={{
                        width: "auto",
                        height: "36px",
                      }}
                      src={withPrefix("/icons8-easy-64.png")}
                      alt="Straightforward"
                    />
                    <h6 className="fs-12 mt-2 text-center">Fast Payout</h6>
                  </div>
                  <div className="col-4 d-flex flex-column justify-content-center align-items-center mt-6">
                    <img
                      style={{
                        width: "auto",
                        height: "36px",
                      }}
                      src={withPrefix("/icons8-low-price-64.png")}
                      alt="Affordable Payments"
                    />
                    <h6 className="fs-12 mt-2 text-center">
                      Affordable Repayments
                    </h6>
                  </div>
                  <div className="col-4 d-flex flex-column justify-content-center align-items-center mt-6">
                    <img
                      style={{
                        width: "auto",
                        height: "36px",
                      }}
                      src={withPrefix("/icons8-trust-64.png")}
                      alt="Trusted Lender"
                    />
                    <h6 className="fs-12 mt-2 text-center">
                      No Prepayment Penalties
                    </h6>
                  </div>
                </div>
                {(!guarantor || (guarantor && activeStepState.selected)) && (
                  <code>
                    <p>
                      All loan offers are contingent on further verifications,
                      including additional bank account, income and identity
                      checks.
                    </p>
                  </code>
                )}
                <p>
                  <small>
                    Interest rates and APRs are updated periodically and are
                    subject to change until you select a loan offer and complete
                    your application, including agreeing to your Truth In
                    Lending Disclosure Statement and signing your loan
                    agreement. You may cancel your application at any time prior
                    to issuance at absolutely no cost to you. There is no
                    downpayment and you may repay your loan any time with no
                    additional costs or pre-payment penalties.
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  ) : null
}

export default LoanOffer
