import React, { useEffect } from "react"
import { Formik, Form } from "formik"
import { Link } from "gatsby"
import * as Yup from "yup"
import "yup-phone"
import { useApplyContext } from "./context"
import StepHeader from "./step-header"
import FormikField from "./formik-field"
import { REST } from "./api-calls-map"
import Modal from "./modal"
import BankAccountDetails from "./bank-account-details"

const RepaymentMethodTypeSchema = Yup.object().shape({
  repaymentMethodType: Yup.string().required("Repayment Method required."),
})

/*global dataLayer */
const RepaymentInfo = ({ guarantor }) => {
  const {
    loanApplication,
    stepLoading,
    setShowTermsModal,
    setTermsModalContent,
    setAppLoading,
    setAppLoadingStatus,
    submitStepAndUpdateAppState,
    API_MAP,
    linkManually,
    setLinkManually,
    linkBankLoading,
    setLinkBankLoading,
    selectedPaymentType,
    setSelectedPaymentType,
    repaymentTypes,
    getRepaymentTypes,
    addRepaymentMethod,
  } = useApplyContext()

  useEffect(() => {
    getRepaymentTypes()
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    const linkToken = localStorage.getItem("linkToken")

    if (urlParams.get("oauth_state_id")) {
      setLinkBankLoading(true)
      setAppLoading(true)
      setAppLoadingStatus("Initializing Bank Account Linking")
      initPlaid(linkToken, true)
    }
  }, [])

  const initPlaid = async (linkToken, sendLinkUri) => {
    const handler = Plaid.create({
      token: linkToken,
      onSuccess: async (public_token, metadata) => {
        setAppLoading(true)
        setAppLoadingStatus("Saving your payment method")

        await REST({
          url: API_MAP.publicTokenSave,
          method: "POST",
          body: { text: public_token },
        })

        submitStepAndUpdateAppState({})

        setAppLoadingStatus(null)
        setAppLoading(false)

        REST({
          url: `${API_MAP.sendPlaidEvent}`,
          method: "POST",
          body: {
            message: JSON.stringify({ public_token, metadata }),
          },
        })
      },
      onLoad: () => {
        setAppLoadingStatus(null)
        setAppLoading(false)
      },
      onExit: (err, metadata) => {
        setLinkBankLoading(false)

        REST({
          url: `${API_MAP.sendPlaidEvent}`,
          method: "POST",
          body: {
            message: JSON.stringify({ err, metadata }),
          },
        })
      },
      onEvent: (eventName, metadata) => {
        REST({
          url: `${API_MAP.sendPlaidEvent}`,
          method: "POST",
          body: {
            message: JSON.stringify({ eventName, metadata }),
          },
        })
      },
      receivedRedirectUri: sendLinkUri ? window.location.href : null,
    })
    handler.open()
  }

  const linkBankAccount = async () => {
    setLinkBankLoading(true)
    setAppLoading(true)
    setAppLoadingStatus("Initializing Bank Account Linking")

    let repaymentMethodTypeGuarantor = repaymentTypes.filter(
      el => el.name === selectedPaymentType
    )[0]

    const [res, status] = await REST({
      url: API_MAP.plaidLinkToken,
      method: "POST",
      body: {
        redirectUri:
          window.location.origin.replace("http", "https") + "/apply/",
        repaymentMethodType: repaymentMethodTypeGuarantor,
      },
    })

    if (status === 200 && res.linkToken) {
      /* global Plaid */
      localStorage.setItem("linkToken", res.linkToken)
      initPlaid(res.linkToken)
    }
  }

  const showOptionOneInfo = () => {
    setShowTermsModal(true)
    setTermsModalContent({
      heightAuto: true,
      hidePrint: true,
      title: "Take over the remaining monthly repayments",
      lastUpdate: null,
      text: (
        <p className="lead-2">
          We will give you notice that Borrower has failed to make two
          successive payments and that we successfully initiated the payments
          from you. The notice will advise that, thereafter, we will commence to
          initiate electronic fund transfers (EFTs) from the Account or the Card
          (and not from Borrower’s bank account or payment card) in the amounts
          and on the dates of the required periodic payments.
        </p>
      ),
    })
  }

  const showOptionTwoInfo = () => {
    setShowTermsModal(true)
    setTermsModalContent({
      hidePrint: true,
      title: "Receive a notice to decide on next steps",
      lastUpdate: null,
      text: (
        <p className="lead-2">
          We will give you notice that Borrower has failed to make two
          successive payments and that we successfully initiated the payments.
          In the notice, we will give you an option:
          <br />
          (a) You may agree that, in the event of a third successive failed
          payment we may accelerate the debt and initiate an electronic fund
          transfer (EFT) on a specified future date for the full amount of the
          accelerated balance.
          <br />
          (b) Alternatively, you may agree that we may initiate EFTs from the
          Account or the Card (and not from Borrower’s bank account or payment
          card) for all subsequent periodic payments.
          <br />
          (c) Finally, you may agree that we may re-set the missed payment count
          to zero and, in the event Borrower fails to make any subsequent
          required periodic payment, at any time after the scheduled payment
          date for the missed payment we may initiate an EFT for the missed
          payment.
          <br />
          In the event you do not affirmatively elect option (a) or option (c),
          you will be deemed to have elected option (b), authorizing us to
          initiate EFTs from the Account or the Card (and not from Borrower’s
          bank account or payment card) for all subsequent periodic payments.
        </p>
      ),
    })
  }

  const showBankLinkServicesAgreementInfo = () => {
    setShowTermsModal(true)
    setTermsModalContent({
      hidePrint: true,
      title: "Bank Link Services Agreement",
      lastUpdate: null,
      text: (
        <p className="lead-2">
          The Bank Link Services Agreement ("Agreement") enables Nextate Credit
          LLC, DBA Trustic ("we" or "us") to perform the Services ("Services")
          described below. By providing your online bank account credentials, as
          described below, you are authorizing us to access, retrieve and store
          your account information maintained online by the bank where you have
          an account and that is accessible to you ("Account Information"),
          including the transaction history, so we can perform the Services.
          Access to your account(s) is enabled by a third-party called Plaid
          Technologies, Inc. ("Plaid"). By agreeing to this Agreement, you are
          also agreeing to Plaid's{" "}
          <a href="https://plaid.com/legal/" target="_blank">
            privacy policy
          </a>
          . You also agree that Plaid is an intended third-party beneficiary
          under this Agreement with respect to the Services.
          <br />
          <b>
            By agreeing to the Services, you agree to the terms set out below,
            including providing your online bank account credentials to us, and
            to our appointment to a limited power of attorney for purposes of
            accessing and retrieving your Account Information as part of the
            Services. Please read this document carefully. If you do not
            understand these terms, or do not accept any part of them, then you
            should not agree to the Services.
            <br />
            Account Credentials
          </b>
          <br />
          By submitting passwords, usernames, PINs, other log-in information,
          materials and other content to us (collectively, your "Account
          Credentials"), you are providing us a limited, personal, royalty-free
          license to your Account Credentials solely for the purpose of
          providing the Services as described below. We may use and, if
          necessary, store your Account Credentials, but only to perform the
          Services. Further, by submitting your Account Credentials to us, you
          represent that you are authorized to submit your Account Credentials
          to us for this purpose. We will use your Account Credentials to log
          you into your bank's website.
          <br />
          <b>
            Limited Power of Attorney to Access and Retrieve Your Account
            Information
          </b>
          <br />
          For purposes of providing the Services and solely to retrieve the
          Account Information as part of this Services, you grant us a limited
          power of attorney, and appoint us as your attorney-in-fact and agent,
          to access and retrieve the Account Information with the full power and
          authority to do and perform each thing necessary in connection with
          the Services, as you could do in person. YOU ACKNOWLEDGE AND AGREE
          THAT WHEN WE ACCESS AND RETRIEVE ACCOUNT INFORMATION FROM YOUR BANK,
          WE ARE ACTING AS YOUR AGENT, AND NOT AS THE AGENT OF OR ON BEHALF OF
          YOUR BANK. The Services are not endorsed nor sponsored by your bank.
          <br />
          <b>Services</b>
          <br />
          By agreeing to the Agreement, you expressly permit us to access,
          retrieve and store your Account Information in order to verify your
          identity and the accuracy of statements made in your online loan
          application (if applicable), perform credit underwriting, and offer
          other services that may be provided by, or through, Trustic. You
          further expressly permit us to access, retrieve, and store your
          Account Information for continuous use in transaction analysis for
          internal risk modeling, use in connection with the servicing of any
          loan you receive through Trustic, credit underwriting for future
          products or services, and marketing of products and services that may
          be offered by, or through, Trustic. The type of Account Information
          that we may access includes, but is not limited to, names and contact
          information associated with individuals on such accounts, income,
          employment, cash- flow, transaction data (including direct deposits),
          and debt obligations. You further agree that we may use your Account
          Credentials in order to deposit funds in your account or initiate
          electronic funds transfers upon your authorization.
          <br />
          WE MAKE NO WARRANTY THAT THE SERVICES AND THE ACCOUNT INFORMATION
          RETRIEVED AS PART OF THIS SERVICE, WILL BE ABLE TO VERIFY YOUR BANK
          ACCOUNT, THAT IT WILL BE TIMELY, SECURE OR ERROR-FREE, NOR THAT IT
          WILL BE ACCURATE OR RELIABLE. Please contact your bank if you believe
          any Account Information is incorrect.
          <br />
          To the extent that our Privacy Policy applies to any of your Account
          Information that we access, retrieve or store, you may review it{" "}
          <Link to="/privacy" target="_blank">
            here.
          </Link>
        </p>
      ),
    })
  }

  return linkBankLoading ? null : (
    <header className="header pt-8 pb-4 mh-100vh">
      <div className="container">
        <div className="row">
          <div className="col-md-7 mx-auto text-left">
            <div className="card">
              <div className="card-body py-0 px-0 py-md-0">
                {linkManually ? (
                  <BankAccountDetails guarantor={guarantor} />
                ) : !selectedPaymentType ? (
                  <>
                    <StepHeader
                      title={
                        guarantor
                          ? "Confirm your payment authorization option"
                          : "Repaying your loan"
                      }
                      description={
                        guarantor ? (
                          <>
                            {loanApplication.borrowerFirstName}'s first monthly
                            payment of $
                            {loanApplication.borrowerOffer.monthlyPayment &&
                              loanApplication.borrowerOffer.monthlyPayment.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}{" "}
                            will be due on{" "}
                            {loanApplication.borrowerOffer.firstRepaymentDate}.
                            <br />
                            <br />
                            If {loanApplication.borrowerFirstName} fails to make
                            their periodic monthly payments, you authorize
                            Trustic to initiate electronic funds transfer from
                            your designated account for the amount of such
                            failed payments. View agreement{" "}
                            <a
                              href="#"
                              onClick={e => {
                                e.preventDefault()
                                setShowTermsModal(
                                  "paymentAuthorizationGuarantor"
                                )
                              }}
                            >
                              here
                            </a>
                            .
                            <br />
                            <br />
                            If {loanApplication.borrowerFirstName} failed to
                            make two periodic monthly payments in a row, and we
                            successfully collected those payments from your
                            account, you can chose one of the following options
                            in regards to {loanApplication.borrowerFirstName}'s
                            loan:
                          </>
                        ) : (
                          `This bit is really important. Your first repayment will be on ${loanApplication.acceptedLoanOffer.firstRepaymentDate}.`
                        )
                      }
                    />
                    <Formik
                      key={1}
                      initialValues={{
                        repaymentMethodType: guarantor
                          ? "OPTION_ONE"
                          : "DIRECT_DEBIT",
                      }}
                      onSubmit={({ repaymentMethodType }) => {
                        setSelectedPaymentType(repaymentMethodType)
                        if (
                          loanApplication.skipBankAccountLink &&
                          loanApplication.skipBankAccountLink
                        ) {
                          setLinkManually(true)
                        }
                        if (!guarantor) {
                          dataLayer.push({
                            event: "borrower_add_details_step3_submitted",
                          })
                          dataLayer.push({
                            borrower_pmt_method: repaymentMethodType,
                          })
                        }
                      }}
                      validationSchema={RepaymentMethodTypeSchema}
                    >
                      {formikProps => (
                        <Form>
                          {!guarantor && (
                            <p className="lead-2 mb-5">
                              How would you like to repay your loan?
                            </p>
                          )}
                          <div className="row no-gap mb-5">
                            <div className="col-md-12">
                              {guarantor ? (
                                <>
                                  <div className="custom-controls-stacked">
                                    <FormikField
                                      type="radio"
                                      name="repaymentMethodType"
                                      placeholder={
                                        <>
                                          {/* Guarantor Option one */}
                                          Take over the remaining monthly
                                          repayments
                                          <i
                                            className="fa fa-info-circle"
                                            aria-hidden="true"
                                            style={{
                                              marginLeft: "4px",
                                              cursor: "pointer",
                                            }}
                                            onClick={e => {
                                              e.preventDefault()
                                              showOptionOneInfo()
                                            }}
                                          ></i>
                                        </>
                                      }
                                      value="OPTION_ONE"
                                      // style={{
                                      //   transform: "scale(1.2)",
                                      //   transformOrigin: "center left",
                                      // }}
                                    />
                                    <FormikField
                                      type="radio"
                                      name="repaymentMethodType"
                                      placeholder={
                                        <>
                                          {/* Guarantor Option two */}
                                          Receive a notice to decide on next
                                          steps
                                          <i
                                            className="fa fa-info-circle"
                                            aria-hidden="true"
                                            style={{
                                              marginLeft: "4px",
                                              cursor: "pointer",
                                            }}
                                            onClick={e => {
                                              e.preventDefault()
                                              showOptionTwoInfo()
                                            }}
                                          ></i>
                                        </>
                                      }
                                      value="OPTION_TWO"
                                      // style={{
                                      //   transform: "scale(1.2)",
                                      //   transformOrigin: "center left",
                                      // }}
                                    />
                                  </div>
                                  <p className="lead-2 mb-5">
                                    Note: If {loanApplication.borrowerFirstName}{" "}
                                    makes their first 12 repayments on time, you
                                    will be released from your guarantee
                                    obligation for this loan.
                                  </p>
                                </>
                              ) : (
                                <div className="custom-controls-stacked">
                                  <FormikField
                                    type="radio"
                                    name="repaymentMethodType"
                                    placeholder="Withdraw payments electronically"
                                    value="DIRECT_DEBIT"
                                    noMargin
                                    // style={{
                                    //   transform: "scale(1.2)",
                                    //   transformOrigin: "center left",
                                    // }}
                                  />
                                  <a
                                    className="d-block mt-2 mb-4"
                                    href="#"
                                    onClick={e => {
                                      e.preventDefault()
                                      setShowTermsModal(
                                        guarantor
                                          ? "paymentAuthorizationGuarantor"
                                          : "paymentAuthorization"
                                      )
                                    }}
                                  >
                                    View agreement
                                  </a>
                                  <FormikField
                                    type="radio"
                                    name="repaymentMethodType"
                                    placeholder="Pay by check instead"
                                    value="CHECK"
                                    // style={{
                                    //   transform: "scale(1.2)",
                                    //   transformOrigin: "center left",
                                    // }}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="row col-8 offset-2">
                              <div className="col-12">
                                <button
                                  type="submit"
                                  className="btn btn-lg btn-dark btn-block"
                                  disabled={!formikProps.isValid}
                                >
                                  {stepLoading ? (
                                    <div
                                      className="loader"
                                      style={{
                                        borderColor: "rgb(255 255 255 / 20%)",
                                        borderLeftColor: "#fff",
                                        fontSize: "2.4px",
                                      }}
                                    />
                                  ) : (
                                    "Next"
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </>
                ) : selectedPaymentType === "DIRECT_DEBIT" ||
                  selectedPaymentType === "OPTION_ONE" ||
                  selectedPaymentType === "OPTION_TWO" ? (
                  <>
                    <StepHeader
                      title="Link your bank account"
                      description={
                        <>
                          <p>Linking your bank:</p>
                          <ul className="pl-5">
                            <li>
                              Tells us which account to use to electronically
                              withdraw your monthly loan payments once your loan
                              is funded
                            </li>
                            <li>Expedites your loan</li>
                            <li>Verifies your information</li>
                          </ul>
                        </>
                      }
                    />
                    <div className="row">
                      <div className="col-12">
                        <button
                          type="button"
                          className="btn btn-lg btn-dark btn-block"
                          onClick={e => {
                            e.stopPropagation()
                            linkBankAccount()
                          }}
                        >
                          {linkBankLoading ? (
                            <div
                              className="loader"
                              style={{
                                borderColor: "rgb(255 255 255 / 20%)",
                                borderLeftColor: "#fff",
                                fontSize: "2.4px",
                              }}
                            />
                          ) : (
                            "Link my Bank"
                          )}
                        </button>
                      </div>
                    </div>
                    <p className="my-5">
                      By linking your bank, you accept the{" "}
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          showBankLinkServicesAgreementInfo()
                        }}
                      >
                        Bank Link Services Agreement
                      </a>{" "}
                      and authorize use of your account for Services which
                      include future credit underwriting and analysis.
                    </p>
                    <div className="row">
                      <div className="col-2 pr-0">
                        <button
                          type="button"
                          className="btn btn-lg btn-dark btn-block px-0"
                          onClick={e => {
                            e.preventDefault()
                            setSelectedPaymentType(null)
                          }}
                        >
                          <span className="ti-angle-left"></span>
                        </button>
                      </div>

                      <div className="col-10">
                        <button
                          type="button"
                          className="btn btn-lg btn-outline-dark btn-block px-1"
                          onClick={() =>
                            Modal.mixin({
                              customClass: {
                                confirmButton:
                                  "btn btn-dark btn-xl fw-600 w-90",
                                cancelButton:
                                  "btn btn-outline-dark btn-xl fw-600 w-90 d-block mt-3",
                              },
                              buttonsStyling: false,
                              backdrop: "#f1f1f1",
                            })
                              .fire({
                                title:
                                  "<strong style='text-transform: uppercase'>Are you sure you want to enter your bank details manually?</strong>",
                                text:
                                  "Your loan may be delayed 2-4 business days, and you may have to submit additional documentation. ",
                                confirmButtonText:
                                  "Yes, enter bank details manually",
                                cancelButtonText: "No, take me back",
                              })
                              .then(result => {
                                if (result.isConfirmed) {
                                  setLinkManually(true)
                                }
                              })
                          }
                        >
                          Manually enter bank details
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <StepHeader
                      title="Repayment Options"
                      description="Automatic Payments are a free and easy way to keep you on track with your monthly loan payments."
                    />
                    <Formik
                      key={3}
                      initialValues={{
                        paymentType: "CHECK",
                      }}
                      onSubmit={values => {
                        values.repaymentMethodType = repaymentTypes.filter(
                          el => el.name === selectedPaymentType
                        )[0]
                        addRepaymentMethod(values)
                      }}
                    >
                      {formikProps => {
                        return (
                          <Form>
                            <p className="lead-2 mb-5">You have selected to:</p>
                            <div className="row no-gap mb-5">
                              <div className="col-md-12">
                                <div className="custom-controls-stacked">
                                  <FormikField type="select" name="paymentType">
                                    <option value="" disabled selected>
                                      Please select a method
                                    </option>
                                    <option value="CHECK">Pay by Check</option>
                                    <option value="DIRECT_DEBIT">
                                      Make Free Automatic Monthly Payments
                                      Electronically
                                    </option>
                                  </FormikField>
                                </div>
                                {formikProps.values.paymentType === "CHECK" ? (
                                  <div className="card border mb-6">
                                    <div className="card-body">
                                      <p>
                                        <span style={{ color: "inherit" }}>
                                          Wait! Are you sure you don't want to
                                          sign up for automatic payments? It's a
                                          free services!
                                        </span>
                                      </p>
                                      <p>
                                        <b style={{ fontWeight: "bold" }}>
                                          The value of automatic payments:
                                        </b>
                                      </p>
                                      <ul className="pl-5">
                                        <li>
                                          Go online or call us anytime for a
                                          payment date change
                                        </li>
                                        <li>
                                          Automatic payments help you prevent
                                          late fees. There is a charge of $15
                                          per missed payment. Yikes!
                                        </li>
                                        <li>You can change this at any time</li>
                                      </ul>
                                      <p>
                                        <b style={{ fontWeight: "bold" }}>
                                          If you decide to pay by check:
                                        </b>
                                      </p>
                                      <ul className="pl-5">
                                        <li>
                                          There are postage fees every month to
                                          mail your check
                                        </li>
                                        <li>
                                          Please, remember to make your check
                                          out to Nextate Credit LLC (DBA
                                          Trustic). Be sure to include your
                                          Trustic Loan Number on your check.
                                        </li>
                                      </ul>
                                      <p>Checks should be sent to:</p>
                                      <p>
                                        Nextate Credit LLC, DBA Trustic
                                        <br />
                                        111 W Jackson Blvd, Suite 1700, Chicago,
                                        IL 60604
                                      </p>
                                      <p>
                                        Are you sure you want to pay by check
                                        instead of free automatic monthly
                                        payments?
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="card border mb-6">
                                    <div className="card-body">
                                      <p>
                                        <b style={{ fontWeight: "bold" }}>
                                          Here is how it works:
                                        </b>
                                      </p>
                                      <ul className="pl-5">
                                        <li>
                                          Each month we will deduct your payment
                                          amount from your bank account;
                                        </li>
                                        <li>No extra fees;</li>
                                        <li>
                                          No work required on your end - but
                                          you'll get a courtesy reminder email
                                          anyway;
                                        </li>
                                        <li>
                                          Call or go online to update you bank
                                          account if needed.
                                        </li>
                                      </ul>
                                      <p>
                                        <b style={{ fontWeight: "bold" }}>
                                          The value of automatic payments:
                                        </b>
                                      </p>
                                      <ul className="pl-5">
                                        <li>
                                          Go online or call us anytime for a
                                          payment date change
                                        </li>
                                        <li>
                                          Automatic payments help you prevent
                                          late fees. There is a charge of $15
                                          per missed payment. Yikes!
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="row col-12 mx-0">
                                {formikProps.values.paymentType === "CHECK" ? (
                                  <>
                                    <div className="col-12 px-0">
                                      <button
                                        type="button"
                                        className="btn btn-lg btn-outline-dark btn-block mb-6"
                                        onClick={e => {
                                          e.preventDefault()
                                          setSelectedPaymentType(null)
                                        }}
                                      >
                                        No, I want automatic payments
                                      </button>
                                    </div>
                                    <div className="col-12 px-0">
                                      <button
                                        type="submit"
                                        className="btn btn-lg btn-dark btn-block"
                                      >
                                        Yes, I want to pay by check
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <div className="col-12 px-0">
                                    <button
                                      type="submit"
                                      className="btn btn-lg btn-dark btn-block"
                                      onClick={e => {
                                        e.preventDefault()
                                        setSelectedPaymentType(null)
                                      }}
                                    >
                                      Got it!
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Form>
                        )
                      }}
                    </Formik>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default RepaymentInfo
