import React from "react"
import { useApplyContext } from "./context"

const SummaryActiveLoanFunded = () => {
  const { loanApplication } = useApplyContext()

  return (
    <>
      <div className="card border mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <p>
                <b>Loan Amount</b>
                <br />$
                {loanApplication.acceptedLoanOffer.loanAmount &&
                  loanApplication.acceptedLoanOffer.loanAmount.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
              </p>
              <p>
                <b>Monthly Payment</b>
                <br />$
                {loanApplication.acceptedLoanOffer.monthlyPayment &&
                  loanApplication.acceptedLoanOffer.monthlyPayment.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
              </p>
            </div>
            <div className="col-6">
              <p>
                <b>Loan Term</b>
                <br />
                {loanApplication.acceptedLoanOffer.loanTermMonths} Months
              </p>
              <p>
                <b>APR</b>
                <br />
                {loanApplication.acceptedLoanOffer.apr &&
                  loanApplication.acceptedLoanOffer.apr.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                %
              </p>
            </div>
          </div>
        </div>
      </div>
      {loanApplication?.productType !== "PERSONAL_LOAN" && (
        <h4 className="fw-300">
          Your loan is guaranteed by: {loanApplication.guarantorFirstName}{" "}
          {loanApplication.guarantorLastName}
        </h4>
      )}
    </>
  )
}

export default SummaryActiveLoanFunded
