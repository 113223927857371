import React from "react"
import { useApplyContext } from "./context"

const SummaryActiveLoan = () => {
  const { loanApplication } = useApplyContext()

  return (
    <>
      <div className="card border mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <p>
                <b>Loan Amount</b>
                <br />$
                {loanApplication.acceptedLoanOffer.loanAmount &&
                  loanApplication.acceptedLoanOffer.loanAmount.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
              </p>
              <p>
                <b>Monthly Payment</b>
                <br />$
                {loanApplication.acceptedLoanOffer.monthlyPayment &&
                  loanApplication.acceptedLoanOffer.monthlyPayment.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
              </p>
            </div>
            <div className="col-6">
              <p>
                <b>Loan Term</b>
                <br />
                {loanApplication.acceptedLoanOffer.loanTermMonths} Months
              </p>
              <p>
                <b>APR</b>
                <br />
                {loanApplication.acceptedLoanOffer.apr &&
                  loanApplication.acceptedLoanOffer.apr.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                %
              </p>
            </div>
          </div>
        </div>
      </div>
      <h4 className="fw-300">
        Congratulations, your loan application has been approved.
      </h4>
      <p className="lead-1">
        Funds are generally deposited via ACH for delivery next business day
        after approval if approved by 2pm Eastern Monday-Friday.
      </p>
      {loanApplication.productType !== "PERSONAL_LOAN" && (
        <p className="lead-1">
          Remember that the loan proceeds will be disbursed to your guarantor’s
          bank account, and it will be their responsibility to give the funds to
          you.
        </p>
      )}
    </>
  )
}

export default SummaryActiveLoan
