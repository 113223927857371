import React from "react"
import { useApplyContext } from "./context"

const SummaryIdleGuarantor = () => {
  const { loanApplication } = useApplyContext()

  return (
    <>
      {loanApplication.borrowerOffer && (
        <>
          <p className="lead-2 mb-5">
            {loanApplication.borrowerFirstName}'s loan details:
          </p>
          <div className="card border mb-4">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <p>
                    <b>Loan Amount</b>
                    <br />$
                    {loanApplication.borrowerOffer.loanAmount &&
                      loanApplication.borrowerOffer.loanAmount.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                  </p>
                  <p>
                    <b>Monthly Payment</b>
                    <br />$
                    {loanApplication.borrowerOffer.monthlyPayment &&
                      loanApplication.borrowerOffer.monthlyPayment.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                  </p>
                </div>
                <div className="col-6">
                  <p>
                    <b>Loan Term</b>
                    <br />
                    {loanApplication.borrowerOffer.loanTermMonths} Months
                  </p>
                  <p>
                    <b>APR</b>
                    <br />
                    {loanApplication.borrowerOffer.apr &&
                      loanApplication.borrowerOffer.apr.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    %
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <h4 className="fw-300">
        Just a few more things before your guarantor application is finalised.
      </h4>
      <p className="lead-1">
        {`We need to verify information that you have provided. We will let you know by email or phone call if we require anything else. Once we verify your information, ${loanApplication.borrowerFirstName}'s loan will be finalized and ready to be funded.`}
      </p>
    </>
  )
}

export default SummaryIdleGuarantor
