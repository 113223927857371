import React from "react"
import { Link } from "gatsby"
import { useApplyContext } from "./context"

const isBrowser = typeof window !== "undefined"

const AppGuarantorRejectedOverlay = () => {
  const { loanApplication, appRejected } = useApplyContext()

  const retryLoanDate = new Date()

  retryLoanDate.setDate(retryLoanDate.getDate() + 30)

  return appRejected ? (
    <div
      className="bg-white"
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 9999,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      <div className="col-11 text-center" style={{ flex: "0" }}>
        <h3>We are sorry, you did not meet our guarantor criteria.</h3>
        <hr />
        <p className="lead-2">
          <h5 className="mb-0 fw-600">
            Unfortunately, we are unable to accept you as a guarantor for{" "}
            {loanApplication && loanApplication.borrowerFirstName}! For more
            information about this decision, please, check a Notice of Adverse
            action that has been sent to your email address.
          </h5>
          If you are currently a guarantor on an existing loan with us, please,
          do not worry as this will not impact the existing loan in any way.
          <br />
          Please, note that this decision will not impact your credit score, as
          only a soft credit inquiry has been generated that is visible only to
          you.
          <h5 className="mb-0 fw-600">What's next?</h5>
          In order to finalize their loan application,{" "}
          {loanApplication && loanApplication.borrowerFirstName} would have to
          find another guarantor.
          <h5 className="mb-0 fw-600">Is there a new guarantor in mind?</h5>
          They should be able to complete their own guarantor application online
          at: {isBrowser && window.location.href}.
        </p>
        <p>
          Please, visit our <Link to="/hub">Q&A Hub</Link> if any questions.
        </p>
      </div>
    </div>
  ) : null
}

export default AppGuarantorRejectedOverlay
