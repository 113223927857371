import React, { useState, useEffect } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import "yup-phone"
import { useApplyContext } from "./context"
import StepHeader from "./step-header"
import FormikField from "./formik-field"
import { REST } from "./api-calls-map"
import USSTATES from "./us-states"

Yup.addMethod(Yup.string, "isPhoneNumberValid", function () {
  return this.test(`test-date-of-birth`, "", function (value) {
    const { path, createError } = this

    if (!!value) {
      if (
        value
          .replace(" ", "")
          .replace("(", "")
          .replace(")", "")
          .replace("-", "")
          .split("_").length > 1
      ) {
        return createError({ path, message: "Iconrect Phone Number" })
      }
    }

    return value
  })
})

const IncomeDetailsInfoSchema = Yup.object().shape({
  incomeSourceType: Yup.string().required("Income Source required."),
  monthlyGrossIncome: Yup.string().required("Monthly Income required."),
  companyName: Yup.string().when("incomeSourceType", {
    is: val => val === "EMPLOYMENT" || val === "SELF-EMPLOYMENT",
    then: Yup.string().required("Company Name required."),
  }),
  jobTitle: Yup.string().when("incomeSourceType", {
    is: val => val === "EMPLOYMENT" || val === "SELF-EMPLOYMENT",
    then: Yup.string().required("Job Title required."),
  }),
  workHours: Yup.string().when("incomeSourceType", {
    is: val => val === "EMPLOYMENT" || val === "SELF-EMPLOYMENT",
    then: Yup.string().required("Hours required."),
  }),
  street: Yup.string().when("incomeSourceType", {
    is: val => val === "EMPLOYMENT",
    then: Yup.string().required("Street Address required."),
  }),
  city: Yup.string().when("incomeSourceType", {
    is: val => val === "EMPLOYMENT",
    then: Yup.string().required("City required."),
  }),
  state: Yup.string().when("incomeSourceType", {
    is: val => val === "EMPLOYMENT",
    then: Yup.string().required("State required."),
  }),
  postalCode: Yup.string().when("incomeSourceType", {
    is: val => val === "EMPLOYMENT",
    then: Yup.string().required("Zip Code required."),
  }),
  phone: Yup.string().when("incomeSourceType", {
    is: val => val === "EMPLOYMENT",
    then: Yup.string()
      .isPhoneNumberValid()
      .required("Work Phone Number required."),
  }),
  incomeSourceDescription: Yup.string().when("incomeSourceType", {
    is: val => val === "OTHER",
    then: Yup.string().required("Income Source required."),
  }),
})

/*global dataLayer */
const IncomeDetailsInfo = ({ guarantor }) => {
  const {
    getLoanApplication,
    setAppLoading,
    setAppLoadingStatus,
    stepLoading,
    activeStep,
    formState,
    submitStepAndUpdateAppState,
    API_MAP,
  } = useApplyContext()

  const activeStepState = formState[activeStep]

  const [newIncome, setNewIncome] = useState(false)

  const [incomes, setIncomes] = useState([])

  const [incomeTypes, setIncomeTypes] = useState([])

  const getIncomeSourceTypes = async () => {
    const [res, status] = await REST({
      url: API_MAP.getIncomeSourceTypes,
      method: "GET",
    })

    if (status === 200) {
      setIncomeTypes(res)
    }
  }

  const getAllIncomeSources = async () => {
    const [res, status] = await REST({
      url: API_MAP.getAllIncomeSources,
      method: "GET",
    })

    if (status === 200) {
      setIncomes(res)
    }
  }

  const addIncomeSource = async values => {
    let loan = await getLoanApplication()

    if (!loan) {
      return false
    }

    const [res, status] = await REST({
      url: API_MAP.addIncomeSource,
      method: "POST",
      body: values,
    })

    if (status === 200) {
      setIncomes(res)
      getAllIncomeSources()
    }
  }

  const deleteIncomeSource = async id => {
    setAppLoading(true)
    setAppLoadingStatus("Removing Income")

    let loan = await getLoanApplication()

    if (!loan) {
      return false
    }

    const [res, status] = await REST({
      url: `${API_MAP.deleteIncomeSource}/${id}`,
      method: "DELETE",
    })

    if (status === 200) {
      getAllIncomeSources()
      setAppLoadingStatus(null)
      setAppLoading(false)
    }
  }

  useEffect(() => {
    getAllIncomeSources()
    getIncomeSourceTypes()
  }, [])

  return (
    <header className="header pt-8 pb-4 mh-100vh">
      <div className="container">
        <div className="row">
          <div className="col-md-7 mx-auto text-left">
            <div className="card">
              <div className="card-body py-0 px-0 py-md-0">
                <StepHeader
                  title="Tell us more about your income"
                  description={
                    guarantor
                      ? "We need to check that you can comfortably guarantee this loan. To do this, please give us a breakdown of your income each month before tax."
                      : "We need to check that you can afford monthly repayments for your loan. To do this, please give us a breakdown of your income each month before tax."
                  }
                />
                {incomes.length && !newIncome ? (
                  <>
                    <h4 className="fw-600 mb-4">Incomes</h4>
                    {incomes.map(
                      (
                        {
                          jobTitle,
                          workHours,
                          monthlyGrossIncome,
                          incomeSourceType,
                          businessId,
                        },
                        key
                      ) => (
                        <div key={key}>
                          <hr
                            className="my-2"
                            style={{ borderColor: "#000" }}
                          />
                          <div className="d-flex w-100 justify-content-between align-items-start">
                            <div className="w-30">
                              <p className="lead-1 fw-800 text-dark mb-0">
                                {jobTitle
                                  ? jobTitle
                                  : incomeSourceType &&
                                    incomeSourceType.description}
                              </p>

                              {!workHours ? (
                                ""
                              ) : workHours === "FULL_TIME" ? (
                                <p className="lead-1 fw-300 text-dark mt-0 mb-0">
                                  Full-Time
                                </p>
                              ) : workHours === "PART_TIME" ? (
                                <p className="lead-1 fw-300 text-dark mt-0 mb-0">
                                  Part-Time
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            <div>
                              <p className="lead-1 fw-800 text-dark my-0">
                                ${monthlyGrossIncome}
                              </p>
                            </div>
                            <div className="w-25">
                              <button
                                type="button"
                                className="btn btn-lg btn-dark btn-block px-0"
                                onClick={() => {
                                  deleteIncomeSource(businessId)
                                }}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                    <hr className="my-2 mb-6" style={{ borderColor: "#000" }} />
                    <button
                      type="button"
                      className="btn btn-lg btn-outline-dark btn-block px-0 mb-6"
                      onClick={() => setNewIncome(true)}
                    >
                      Add Another Income
                    </button>
                    <button
                      type="button"
                      className="btn btn-lg btn-dark btn-block px-0 mb-4"
                      onClick={() => {
                        submitStepAndUpdateAppState({})
                        if (!guarantor) {
                          dataLayer.push({
                            event: "borrower_add_details_step2_submitted",
                          })
                        }
                      }}
                    >
                      {stepLoading ? (
                        <div
                          className="loader"
                          style={{
                            borderColor: "rgb(255 255 255 / 20%)",
                            borderLeftColor: "#fff",
                            fontSize: "2.4px",
                          }}
                        />
                      ) : (
                        "I have no other income"
                      )}
                    </button>
                  </>
                ) : (
                  <Formik
                    initialValues={activeStepState}
                    onSubmit={values => {
                      const incomeType = values.incomeSourceType

                      const incomeTypeObj = incomeTypes.filter(
                        income => income.name === incomeType
                      )[0]

                      addIncomeSource(
                        incomeType === "EMPLOYMENT"
                          ? {
                              street: values.street,
                              city: values.city,
                              state: values.state,
                              postalCode: values.postalCode,
                              companyName: values.companyName,
                              workHours: values.workHours,
                              incomeSourceType: incomeTypeObj,
                              jobTitle: values.jobTitle,
                              monthlyGrossIncome: values.monthlyGrossIncome,
                              phone: values.phone,
                            }
                          : incomeType === "SELF_EMPLOYMENT"
                          ? {
                              companyName: values.companyName,
                              workHours: values.workHours,
                              incomeSourceType: incomeTypeObj,
                              jobTitle: values.jobTitle,
                              monthlyGrossIncome: values.monthlyGrossIncome,
                            }
                          : incomeType === "RETIREMENT"
                          ? {
                              incomeSourceType: incomeTypeObj,
                              monthlyGrossIncome: values.monthlyGrossIncome,
                            }
                          : {
                              incomeSourceType: incomeTypeObj,
                              incomeSourceDescription:
                                values.incomeSourceDescription,
                              monthlyGrossIncome: values.monthlyGrossIncome,
                            }
                      )

                      if (!guarantor) {
                        dataLayer.push({
                          borrower_income_type: incomeType,
                        })
                      }
                      setNewIncome(false)
                    }}
                    validationSchema={IncomeDetailsInfoSchema}
                  >
                    {formikProps => {
                      const showIfEmplOnly =
                        formikProps.values.incomeSourceType === "EMPLOYMENT"

                      const showIfOther =
                        formikProps.values.incomeSourceType === "OTHER"

                      const showIfEmplOrSelf =
                        formikProps.values.incomeSourceType === "EMPLOYMENT" ||
                        formikProps.values.incomeSourceType ===
                          "SELF_EMPLOYMENT"

                      return (
                        <Form>
                          <div className="row no-gap mb-5">
                            <div className="col-md-12">
                              <div className="custom-controls-stacked">
                                <FormikField
                                  type="select"
                                  name="incomeSourceType"
                                  onChange={e => {
                                    formikProps.setFieldValue(
                                      "incomeSourceType",
                                      incomeTypes.filter(
                                        income => income.name === e.target.value
                                      )[0].name
                                    )
                                  }}
                                >
                                  <option value="" selected disabled>
                                    What income do you receive
                                  </option>
                                  {incomeTypes.map((e, k) => (
                                    <option value={e.name} key={k}>
                                      {e.description}
                                    </option>
                                  ))}
                                </FormikField>
                                {formikProps.values.incomeSourceType !== "" && (
                                  <>
                                    <FormikField
                                      type="currency"
                                      name="monthlyGrossIncome"
                                      prefix="$"
                                      placeholder="Monthly $"
                                    />
                                    {showIfEmplOrSelf && (
                                      <FormikField
                                        type="text"
                                        name="companyName"
                                        placeholder="Company Name"
                                      />
                                    )}
                                    {showIfEmplOrSelf && (
                                      <FormikField
                                        type="text"
                                        name="jobTitle"
                                        placeholder="Job Title"
                                      />
                                    )}
                                    {showIfEmplOrSelf && (
                                      <FormikField
                                        type="select"
                                        name="workHours"
                                      >
                                        <option value="" disabled selected>
                                          Hours
                                        </option>
                                        <option value="FULL_TIME">
                                          Full-Time
                                        </option>
                                        <option value="PART_TIME">
                                          Part-Time
                                        </option>
                                      </FormikField>
                                    )}
                                    {showIfEmplOnly && (
                                      <>
                                        <FormikField
                                          type="text"
                                          name="street"
                                          placeholder="Street Address"
                                        />
                                        <FormikField
                                          type="text"
                                          name="city"
                                          placeholder="City"
                                        />
                                        <FormikField type="select" name="state">
                                          <option value="" disabled selected>
                                            Please select a State
                                          </option>
                                          {USSTATES.map(
                                            ({ name, abbreviation }, key) => (
                                              <option
                                                value={abbreviation}
                                                key={key}
                                              >
                                                {name}
                                              </option>
                                            )
                                          )}
                                        </FormikField>
                                        <FormikField
                                          type="text"
                                          name="postalCode"
                                          placeholder="Zip Code"
                                        />
                                      </>
                                    )}
                                    {showIfEmplOnly && (
                                      <FormikField
                                        type="tel"
                                        name="phone"
                                        placeholder="Work Phone Number"
                                      />
                                    )}
                                    {showIfOther && (
                                      <FormikField
                                        type="text"
                                        name="incomeSourceDescription"
                                        placeholder="Income Source"
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="row col-8 offset-2">
                              {newIncome && (
                                <div className="col-2 px-0">
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-dark btn-block px-0"
                                    onClick={() => setNewIncome(false)}
                                  >
                                    <span className="ti-angle-left"></span>
                                  </button>
                                </div>
                              )}
                              <div className="col-10">
                                <button
                                  type="submit"
                                  className="btn btn-lg btn-dark btn-block px-0"
                                  disabled={!formikProps.isValid}
                                >
                                  {stepLoading ? (
                                    <div
                                      className="loader"
                                      style={{
                                        borderColor: "rgb(255 255 255 / 20%)",
                                        borderLeftColor: "#fff",
                                        fontSize: "2.4px",
                                      }}
                                    />
                                  ) : (
                                    "Save Income"
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default IncomeDetailsInfo
