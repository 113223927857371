import React from "react"
import { useApplyContext } from "./context"

const SummaryActiveGuarantorFunded = () => {
  const { loanApplication } = useApplyContext()

  return (
    <>
      <div className="card border mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <p>
                <b>Loan Amount</b>
                <br />$
                {loanApplication.borrowerOffer.loanAmount &&
                  loanApplication.borrowerOffer.loanAmount.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
              </p>
              <p>
                <b>Monthly Payment</b>
                <br />$
                {loanApplication.borrowerOffer.monthlyPayment &&
                  loanApplication.borrowerOffer.monthlyPayment.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
              </p>
            </div>
            <div className="col-6">
              <p>
                <b>Loan Term</b>
                <br />
                {loanApplication.borrowerOffer.loanTermMonths} Months
              </p>
              <p>
                <b>APR</b>
                <br />
                {loanApplication.borrowerOffer.apr &&
                  loanApplication.borrowerOffer.apr.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                %
              </p>
            </div>
          </div>
        </div>
      </div>
      <h4 className="fw-300">
        {loanApplication.borrowerFirstName}'s loan has been funded. We will keep
        you posted regarding the loan status via email.
      </h4>
    </>
  )
}

export default SummaryActiveGuarantorFunded
