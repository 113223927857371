import React from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import "yup-phone"
import { useApplyContext } from "./context"
import StepHeader from "./step-header"
import FormikField from "./formik-field"

const IncomeInfoSchema = Yup.object().shape({
  income: Yup.number().moreThan(0, "Income required.")
    .typeError("Income must be a number")
    .required("Income required."),
})

/*global dataLayer */
const IncomeInfo = ({ guarantor }) => {
  const {
    API_MAP,
    stepLoading,
    activeStep,
    setActiveStep,
    formState,
    submitStepAndUpdateAppState,
  } = useApplyContext()

  const activeStepState = formState[activeStep]

  return (
    <header className="header pt-8 pb-4 mh-100vh">
      <div className="container">
        <div className="row">
          <div className="col-md-7 mx-auto text-left">
            <div className="card">
              <div className="card-body py-0 px-0 py-md-0">
                <StepHeader
                  activeStep={activeStep}
                  title="How much do you make in a year?"
                  description="Your salary, retirement pay, or part-time jobs count."
                />
                <Formik
                  enableReinitialize
                  initialValues={activeStepState}
                  onSubmit={values => {
                    submitStepAndUpdateAppState(
                      guarantor
                        ? API_MAP.updateGuarantorApplication
                        : API_MAP.loanApplication,
                      { ...values, submitAction: false }
                    )
                    if (!guarantor) {
                      dataLayer.push({ event: "borrower_step4_submitted" })
                      dataLayer.push({
                        borrower_annual_income: values.income,
                      })
                    }
                  }}
                  validationSchema={IncomeInfoSchema}
                >
                  {formikProps => (
                    <Form>
                      <div className="row no-gap mb-5">
                        <div className="col-md-12">
                          <FormikField
                            type="currency"
                            name="income"
                            prefix="$"
                            placeholder="Total annual income, pre-tax."
                          />
                        </div>
                        <div className="row col-8 offset-2">
                          <div className="col-2 px-0">
                            <button
                              type="button"
                              className="btn btn-lg btn-dark btn-block px-0"
                              onClick={() => setActiveStep(activeStep - 1)}
                            >
                              <span className="ti-angle-left"></span>
                            </button>
                          </div>
                          <div className="col-10">
                            <button
                              type="submit"
                              className="btn btn-lg btn-dark btn-block"
                              disabled={!formikProps.isValid}
                            >
                              {stepLoading ? (
                                <div
                                  className="loader"
                                  style={{
                                    borderColor: "rgb(255 255 255 / 20%)",
                                    borderLeftColor: "#fff",
                                    fontSize: "2.4px",
                                  }}
                                />
                              ) : (
                                "Next"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default IncomeInfo
