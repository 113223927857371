import React, { useEffect } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import "yup-phone"
import { ApplyProvider, useApplyContext } from "./context"
import StepHeader from "./step-header"
import FormikField from "./formik-field"
import USSTATES from "./us-states"
import AppLoadingOverlay from "./app-loading-overlay"
import Modal from "./modal"
import AppTermsModal from "./app-terms-modal"

const RepaymentInfoSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name required."),
  lastName: Yup.string().required("Last Name required."),
  routingNumber: Yup.string()
    .trim()
    .length(9, "Routing Number must have 9 digits")
    .matches(/^\d+$/, "Routing Number must be a number")
    .required("Routing Number required."),
  bankName: Yup.string().required("Bank Name required."),
  accountNumber: Yup.string()
    .trim()
    .matches(/^\d+$/, "Account Number must be a number")
    .required("Account Number required."),
  confirmAccountNumber: Yup.string()
    .trim()
    .matches(/^\d+$/, "Account Number must be a number")
    .oneOf([Yup.ref("accountNumber"), null], "Account Number don't match!")
    .required("Confirm Account Number required."),
  consent: Yup.bool().oneOf(
    [true],
    "Please, check the box to confirm you’ve read and agree to the terms above."
  ),
  achConsent: Yup.bool().oneOf(
    [true],
    "Please, check the box to confirm you’ve read and agree to the terms above."
  ),
  accountType: Yup.string().required("Account Type required."),
  addressLine1: Yup.string().required("Address Line 1 required."),
  city: Yup.string().required("City required."),
  state: Yup.string().required("State required."),
  // .test("state", "State must be Arizona", val => val && val == "AZ"),
  postalCode: Yup.string().required("Zip Code required."),
})

const UpdateBankAccountGuarantor = ({ guarantor }) => {
  const {
    stepLoading,
    setShowTermsModal,
    setTermsModalContent,
    updateRepaymentMethod,
    setAppLoading,
  } = useApplyContext()

  useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    setTimeout(() => setAppLoading(false), 1000)
  }, [])

  return (
    <>
      <header className="header pt-8 pb-4 mh-100vh">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mx-auto text-left">
              <StepHeader
                title="Enter your bank details below"
                description={
                  <>
                    We will use this account to automatically withdraw payments
                    in accordance with your personal guarantee agreement.
                    Please, use a bank account on which you are listed as an
                    account holder.
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      style={{
                        marginLeft: "4px",
                      }}
                      onClick={() => {
                        setShowTermsModal(true)
                        setTermsModalContent({
                          title: "What is an account holder?",
                          hidePrint: true,
                          lastUpdate: null,
                          text: (
                            <p className="lead-2">
                              You must be an account holder of the bank account.
                              An account holder is a person whose name is listed
                              on the bank account. In case of joint bank
                              accounts, both parties are listed on the bank
                              account. If you don’t have a bank account on which
                              you are listed as an account holder, you won’t be
                              accepted as a guarantor.
                            </p>
                          ),
                        })
                      }}
                    ></i>
                  </>
                }
              />
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  routingNumber: "",
                  bankName: "",
                  accountNumber: "",
                  confirmAccountNumber: "",
                  consent: false,
                  achConsent: false,
                  accountType: "",
                  addressLine1: "",
                  addressLine2: "",
                  city: "",
                  state: "",
                  postalCode: "",
                }}
                onSubmit={async (values, { setFieldError, resetForm }) => {
                  const [res, status] = await updateRepaymentMethod(values)

                  if (
                    res &&
                    status === 400 &&
                    res.message === "You should be owner of the bank account."
                  ) {
                    res.errors.map(({ element, message }) =>
                      setFieldError(element, message)
                    )

                    resetForm({
                      firstName: "",
                      lastName: "",
                      routingNumber: "",
                      bankName: "",
                      accountNumber: "",
                      confirmAccountNumber: "",
                      consent: false,
                      achConsent: false,
                      accountType: "",
                      addressLine1: "",
                      addressLine2: "",
                      city: "",
                      state: "",
                      postalCode: "",
                    })
                  }

                  if (
                    res &&
                    ((status === 200 && !res.active) ||
                      (status === 400 && res.message))
                  ) {
                    resetForm({
                      firstName: "",
                      lastName: "",
                      routingNumber: "",
                      bankName: "",
                      accountNumber: "",
                      confirmAccountNumber: "",
                      consent: false,
                      achConsent: false,
                      accountType: "",
                      addressLine1: "",
                      addressLine2: "",
                      city: "",
                      state: "",
                      postalCode: "",
                    })

                    Modal.mixin({
                      customClass: {
                        confirmButton: "btn btn-dark btn-xl fw-600 w-40",
                        cancelButton: "btn btn-danger btn-xl fw-600 w-40",
                      },
                      buttonsStyling: false,
                      backdrop: "#f1f1f1",
                    }).fire({
                      title:
                        "<strong style='text-transform: uppercase'>Hmm...</strong>",
                      text:
                        res.message ||
                        "Unfortunately we can accept only bank accounts with an address in Arizona. Please, enter a different bank account now.",
                      confirmButtonText: "Ok",
                    })
                  }
                }}
                validationSchema={RepaymentInfoSchema}
              >
                {formikProps => {
                  return (
                    <Form>
                      <div className="row no-gap mb-5">
                        <div className="col-md-12">
                          <div className="custom-controls-stacked">
                            <FormikField
                              type="text"
                              name="firstName"
                              placeholder="First Name"
                            />
                            <FormikField
                              type="text"
                              name="lastName"
                              placeholder="Last Name"
                            />
                            <FormikField
                              type="text"
                              name="routingNumber"
                              placeholder="Routing Number"
                            />
                            <FormikField
                              type="text"
                              name="bankName"
                              placeholder="Bank Name"
                            />
                            <FormikField
                              type="text"
                              name="accountNumber"
                              placeholder="Account Number"
                            />
                            <FormikField
                              type="text"
                              name="confirmAccountNumber"
                              placeholder="Confirm Account Number"
                            />
                            <FormikField type="select" name="accountType">
                              <option value="" disabled selected>
                                Please select Account Type
                              </option>
                              <option value={"CHECKING"}>Checking</option>
                              <option value={"SAVINGS"}>Savings</option>
                            </FormikField>
                            <FormikField
                              type="text"
                              name="addressLine1"
                              placeholder="Address Line 1"
                            />
                            <FormikField
                              type="text"
                              name="addressLine2"
                              placeholder="Address Line 2"
                            />
                            <FormikField
                              type="text"
                              name="city"
                              placeholder="City"
                            />
                            <FormikField type="select" name="state">
                              <option value="" disabled selected>
                                Please select a State
                              </option>
                              {USSTATES.map(({ name, abbreviation }, key) => (
                                <option value={abbreviation} key={key}>
                                  {name}
                                </option>
                              ))}
                            </FormikField>
                            <FormikField
                              type="text"
                              name="postalCode"
                              placeholder="Zip Code"
                            />
                            <FormikField
                              type="checkbox"
                              name="consent"
                              textWith={100}
                              placeholder={
                                <>
                                  I authorize Trustic to{" "}
                                  <a
                                    href="#"
                                    onClick={e => {
                                      e.preventDefault()
                                      setShowTermsModal(true)
                                      setTermsModalContent({
                                        title: "Bank Account Verification",
                                        hidePrint: true,
                                        lastUpdate: null,
                                        text: (
                                          <p className="lead-2">
                                            To verify your bank account, Trustic
                                            will initiate a debit and a credit
                                            for the same amount (under a dollar)
                                            against your bank account. When you
                                            see this activity post to your bank
                                            account (usually within 2-4 business
                                            days after you provide your bank
                                            account information), sign into
                                            Trustic, click on the "Verify your
                                            bank account" link in your Account
                                            Summary, and confirm the transaction
                                            amount.
                                          </p>
                                        ),
                                      })
                                    }}
                                  >
                                    verify your bank account
                                  </a>
                                  .
                                </>
                              }
                            />
                            <FormikField
                              type="checkbox"
                              name="achConsent"
                              textWith={100}
                              placeholder={
                                <a
                                  href="#"
                                  onClick={e => {
                                    e.preventDefault()
                                    setShowTermsModal(
                                      "paymentAuthorizationGuarantor"
                                    )
                                  }}
                                >
                                  I consent to withdraw payments electronically.
                                </a>
                              }
                            />
                          </div>
                        </div>
                        <div className="row col-8 offset-2">
                          <div className="col-2 px-0">
                            <button
                              type="button"
                              className="btn btn-lg btn-dark btn-block px-0"
                              onClick={e => {
                                e.preventDefault()
                                window.history.back()
                              }}
                            >
                              <span className="ti-angle-left"></span>
                            </button>
                          </div>
                          <div className="col-10">
                            <button
                              type="submit"
                              className="btn btn-lg btn-dark btn-block"
                              disabled={!formikProps.isValid}
                            >
                              {stepLoading ? (
                                <div
                                  className="loader"
                                  style={{
                                    borderColor: "rgb(255 255 255 / 20%)",
                                    borderLeftColor: "#fff",
                                    fontSize: "2.4px",
                                  }}
                                />
                              ) : (
                                "Update"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </header>
      <AppTermsModal />
      <AppLoadingOverlay />
    </>
  )
}

const UpdateBankAccountGuarantorHOC = props => (
  <ApplyProvider>
    <UpdateBankAccountGuarantor {...props} />
  </ApplyProvider>
)

export default UpdateBankAccountGuarantorHOC
