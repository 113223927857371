import React, { useRef, useState, useEffect } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { withPrefix } from "gatsby"
import { useApplyContext } from "./context"
import { REST } from "./api-calls-map"
import FormikField from "./formik-field"

const SignAgreementSchema = Yup.object().shape({
  consent: Yup.bool().oneOf(
    [true],
    "Please, check the box to confirm you’ve read and agree to the terms above."
  ),
})

const SignAgreement = ({ setSignAgreement, guarantor }) => {
  const {
    loanApplication,
    setAppLoading,
    setAppLoadingStatus,
    API_MAP,
  } = useApplyContext()

  const loanDocRef = useRef()

  const iframeRef = useRef()

  const [contractLink, setContractLink] = useState(null)

  const [contractId, setContractId] = useState(null)

  const signContract = async () => {
    setAppLoadingStatus("Signing Agreement")
    setAppLoading(true)
    const [res, status] = await REST({
      url: `${
        guarantor ? API_MAP.signGuarantorContract : API_MAP.signContract
      }/${contractId && contractId}/sign`,
      method: "POST",
      body: {
        signed: true,
      },
    })

    if (status === 200) {
      setAppLoadingStatus(null)
      setAppLoading(false)
      setSignAgreement(false)
      typeof window !== "undefined" && window.location.reload()
    }
  }

  const getContract = async () => {
    setAppLoadingStatus("Loading Agreement")
    setAppLoading(true)
    const [res, status] = await REST({
      url: guarantor
        ? API_MAP.getLoanApplicationGuarantorContract
        : API_MAP.getLoanApplicationBorrowerContract,
      method: "GET",
    })

    if (status === 200) {
      setContractLink(
        guarantor
          ? API_MAP.getPageGuarantorContract
          : API_MAP.getPageBorrowerContract
      )
      setContractId(res.businessId)
    }
  }

  useEffect(() => {
    loanApplication.contractStatus && getContract()
  }, [])

  return (
    <header className="header pt-8 pb-4 mh-100vh">
      <div className="container">
        <div className="row">
          <div className="col-md-7 mx-auto text-left">
            <Formik
              initialValues={{ consent: false }}
              validationSchema={SignAgreementSchema}
              onSubmit={() => signContract()}
            >
              {formikProps => (
                <Form>
                  <div className="row no-gap mb-5">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body py-0 px-0 py-md-0">
                          {!guarantor && (
                            <h5 className="fw-400 w-100 my-3">
                              Loan funding is subject to further verifications.
                            </h5>
                          )}
                          <div className="card border mb-4">
                            <div className="card-body p-0" ref={loanDocRef}>
                              <iframe
                                ref={iframeRef}
                                style={{
                                  width: "100%",
                                  height: "380px",
                                  border: 0,
                                }}
                                src={contractLink}
                                onLoad={e => {
                                  // if (
                                  //   iframeRef.current &&
                                  //   iframeRef.current.contentWindow.document
                                  // ) {
                                  //   iframeRef.current.contentWindow.document.body.style.zoom = `${
                                  //     loanDocRef.current.offsetWidth / 1115
                                  //   }`
                                  // }
                                  if (iframeRef.current) {
                                    iframeRef.current.contentWindow.postMessage(
                                      {
                                        sender: "scaleBody",
                                        message:
                                          loanDocRef.current.offsetWidth / 1130,
                                      }
                                    )
                                  }
                                  setAppLoadingStatus(null)
                                  setAppLoading(false)
                                }}
                              />
                            </div>
                          </div>
                          {/* {!guarantor && (
                            <h5 className="fw-300 mb-5">
                              The proceeds of this loan will be disbursed
                              directly to Guarantor as your agent. You should
                              not take this loan unless you trust Guarantor to
                              forward the loan proceeds to you.
                            </h5>
                          )} */}
                        </div>
                      </div>
                      {loanApplication.contractStatus === "SIGNED" ? null : (
                        <FormikField
                          type="checkbox"
                          name="consent"
                          placeholder={
                            guarantor
                              ? "Clicking the checkbox constitutes your electronic signature and acceptance of the Personal Guarantee Agreement above"
                              : "Clicking the checkbox constitutes your electronic signature and acceptance of the Consumer Installment Loan Agreement above"
                          }
                        />
                      )}
                      <div className="row col-12 mx-0 px-0">
                        <div className="col-6 px-0">
                          <a
                            download
                            className="btn btn-xl btn-secondary w-100"
                            href={
                              guarantor
                                ? API_MAP.downloadApplicationGuarantorContract
                                : API_MAP.downloadApplicationBorrowerContract
                            }
                            target="_blank"
                            onClick={e => e.stopPropagation()}
                          >
                            Print
                          </a>
                        </div>
                        <div className="col-6">
                          <button
                            type="button"
                            className="btn btn-xl btn-secondary btn-block"
                            onClick={e => {
                              e.stopPropagation()
                              setAppLoadingStatus(null)
                              setAppLoading(false)
                              setSignAgreement(false)
                            }}
                          >
                            Close
                          </button>
                        </div>
                        {loanApplication.contractStatus !== "SIGNED" && (
                          <div className="col-12 pl-0 mt-4">
                            <button
                              type="submit"
                              className="btn btn-xl btn-dark btn-block"
                              disabled={!formikProps.isValid}
                            >
                              I Agree
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </header>
  )
}

export default SignAgreement
