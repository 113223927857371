import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import ResetPassword from "../components/apply/reset-password"
import Login from "../components/apply/login"
import Default from "../components/apply"
import Guarantor from "../components/apply/guarantor"
import UpdateBankAccountDetails from "../components/apply/update-bank-account-details"
import UpdateBankAccountBorrower from "../components/apply/update-bank-account-borrower"
import UpdateBankAccountGuarantor from "../components/apply/update-bank-account-guarantor"
import AddDisbursementBankAccount from "../components/apply/add-disbursement-bank-account"
import Seo from "../components/seo"

const Apply = () => {
  return (
    <>
      <Layout app dark={true}>
        <Router basepath="/apply">
          <ResetPassword path="/reset-password" />
          <Login path="/login" />
          <Guarantor path="/guarantor/:id" />
          <UpdateBankAccountDetails path="/update-bank-account" />
          <UpdateBankAccountBorrower path="/update-bank-account-borrower" />
          <UpdateBankAccountGuarantor path="/update-bank-account-guarantor" />
          <AddDisbursementBankAccount path="/add-disbursement-bank-account" />
          <Default path="/" />
        </Router>
      </Layout>
      <Seo
        title="Trustic | Apply Now"
        description="Apply for a guarantor personal loan of up to $3,000 now"
      />
    </>
  )
}
export default Apply
