import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import "yup-phone"
import { useApplyContext } from "./context"
import StepHeader from "./step-header"
import FormikField from "./formik-field"
import { REST } from "./api-calls-map"

const RelationshipInfoSchema = Yup.object().shape({
  relationship: Yup.string().required("Relationship required."),
})

const RelationshipInfo = ({ loanInvitationId }) => {
  const {
    API_MAP,
    stepLoading,
    activeStep,
    setActiveStep,
    formState,
    setStepLoading,
    setShowTermsModal,
    setTermsModalContent,
    loggedIn,
    isNotFirstApplication,
  } = useApplyContext()

  const activeStepState = formState[activeStep]

  const [borrowerInfo, setBorrowerInfo] = useState([])

  const getGuarantorBorrowerDetails = async () => {
    const [res, status] = await REST({
      url: `${API_MAP.getGuarantorBorrowerDetails}/${loanInvitationId}`,
      method: "GET",
    })

    if (status === 200) {
      setBorrowerInfo(res)
    }
  }

  const initGuarantorApplication = async values => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    setStepLoading(true)

    const [res, status] = await REST({
      url: `${API_MAP.initGuarantorApplication}`,
      method: "POST",
      body: { ...values, loanApplicationBusinessId: loanInvitationId },
    })

    if (status === 200) {
      setStepLoading(false)
      localStorage.setItem("businessId", res.businessId)
      localStorage.setItem("loanInvitationId", loanInvitationId)
      setActiveStep(activeStep + 1)
      // if (loggedIn) {
      //   navigate(`${window.location.pathname}?resumeApp=true`, {
      //     replace: true,
      //   })
      // }
    }
  }

  useEffect(() => {
    getGuarantorBorrowerDetails()
  }, [])

  return borrowerInfo ? (
    <header className="header pt-8 pb-4 mh-100vh">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto text-left">
            <div className="card">
              <div className="card-body py-0 px-0 py-md-0">
                <StepHeader
                  activeStep={activeStep}
                  title={
                    borrowerInfo && (
                      <>
                        {borrowerInfo.firstName} has invited you to be their
                        Guarantor!
                      </>
                    )
                  }
                />
                <Formik
                  enableReinitialize
                  isInitialValid={isNotFirstApplication}
                  initialValues={activeStepState}
                  onSubmit={values => {
                    initGuarantorApplication(values)
                  }}
                  validationSchema={RelationshipInfoSchema}
                >
                  {formikProps => (
                    <Form>
                      <div className="row no-gap mb-5">
                        {borrowerInfo && (
                          <div className="col-md-12">
                            <p className="lead-2">
                              By agreeing to help {borrowerInfo.firstName} you
                              are joining our community of guarantors who have
                              already helped their friends and family. So, thank
                              you.
                            </p>
                            <p className="fw-600 lead-2">
                              {borrowerInfo.firstName} is borrowing up to $
                              {borrowerInfo.amount &&
                                borrowerInfo.amount.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}{" "}
                              over 36 months, with APR of{" "}
                              {borrowerInfo.apr &&
                                borrowerInfo.apr.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              %. Their monthly repayment will not exceed $
                              {borrowerInfo.monthlyPayment &&
                                borrowerInfo.monthlyPayment.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              .
                            </p>
                            <p className="lead-2">
                              To be a Guarantor for {borrowerInfo.firstName} you
                              need to:
                            </p>
                            <ul className="lead-2">
                              <li>Be aged at least 18 years old</li>
                              <li>Be a resident in Arizona</li>
                              <li>Have a social security number</li>
                              <li>Have a strong credit history</li>
                              <li>
                                Have a regular income, and be able to afford the
                                loan repayments if {borrowerInfo.firstName}{" "}
                                can’t pay
                              </li>
                              <li>
                                Have a US bank account where we can deposit{" "}
                                {borrowerInfo.firstName}'s loan
                                <i
                                  className="fa fa-info-circle"
                                  aria-hidden="true"
                                  style={{
                                    marginLeft: "4px",
                                  }}
                                  onClick={() => {
                                    setShowTermsModal(true)
                                    setTermsModalContent({
                                      title:
                                        "Important information about bank account ownership",
                                      hidePrint: true,
                                      lastUpdate: null,
                                      text: (
                                        <>
                                          <p className="lead-2">
                                            You must be an account holder of the
                                            bank account. An account holder is a
                                            person whose name is listed on the
                                            bank account. In case of joint bank
                                            accounts, both parties are listed on
                                            the bank account. If you don’t have
                                            a bank account on which you are
                                            listed as an account holder, you
                                            won’t be accepted as a guarantor.
                                          </p>
                                        </>
                                      ),
                                    })
                                  }}
                                ></i>
                              </li>
                            </ul>
                            <p className="lead-2">Please, note that:</p>
                            <ul className="lead-2">
                              <li>
                                {borrowerInfo.firstName}
                                's loan won’t affect your credit score nor your
                                ability to borrow as long as the loan is in good
                                standing;
                              </li>
                              <li>
                                {borrowerInfo.firstName}
                                's loan funds will be disbursed to your bank
                                account. It will be your responsibility to give
                                the funds to {borrowerInfo.firstName} ;
                              </li>
                              <li>
                                You can benefit from the Guarantor Release
                                option, if {borrowerInfo.firstName} makes their
                                first 12 monthly loan payments on time.
                              </li>
                            </ul>
                            <p className="lead-2">
                              Let’s get started. It shouldn’t take more than 10
                              minutes.{" "}
                            </p>
                            <p className="fw-600 lead-2">
                              What’s your relationship to{" "}
                              {borrowerInfo.firstName} ?
                            </p>
                            <FormikField type="select" name="relationship">
                              <option value="" disabled selected>
                                Please select a relationship
                              </option>
                              <option value="Their friend">Their friend</option>
                              <option value="Their father">Their father</option>
                              <option value="Their mother">Their mother</option>
                              <option value="Partner">Partner</option>
                              <option value="Spouse">Spouse</option>
                              <option value="Sister">Sister</option>
                              <option value="Brother">Brother</option>
                              <option value="Father-in-law">
                                Father-in-law
                              </option>
                              <option value="Mother-in-law">
                                Mother-in-law
                              </option>
                              <option value="Grandmother">Grandmother</option>
                              <option value="Grandfather">Grandfather</option>
                              <option value="Cousin">Cousin</option>
                              <option value="Daughter">Daughter</option>
                              <option value="Son">Son</option>
                              <option value="Aunt">Aunt</option>
                              <option value="Uncle">Uncle</option>
                              <option value="Brother-in-law">
                                Brother-in-law
                              </option>
                              <option value="Sister-in-law">
                                Sister-in-law
                              </option>
                              <option value="Son-in-law">Son-in-law</option>
                              <option value="Daughter-in-law">
                                Daughter-in-law
                              </option>
                              <option value="Employer">Employer</option>
                              <option value="Employee">Employee</option>
                              <option value="Niece">Niece</option>
                              <option value="Nephew">Nephew</option>
                              <option value="Neighbour">Neighbour</option>
                              <option value="Landlord">Landlord</option>
                            </FormikField>
                          </div>
                        )}
                        <div className="row col-8 offset-2">
                          <div className="col-2 px-0">
                            <Link
                              to="/"
                              className="btn btn-lg btn-dark btn-block px-0"
                            >
                              <span className="ti-angle-left text-white"></span>
                            </Link>
                          </div>
                          <div className="col-10">
                            <button
                              type="submit"
                              className="btn btn-lg btn-dark btn-block"
                              disabled={!formikProps.isValid}
                            >
                              {stepLoading ? (
                                <div
                                  className="loader"
                                  style={{
                                    borderColor: "rgb(255 255 255 / 20%)",
                                    borderLeftColor: "#fff",
                                    fontSize: "2.4px",
                                  }}
                                />
                              ) : (
                                "Next"
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="row col-12">
                          <div className="col-12 mt-4">
                            <p className="lead-2">
                              <b>For your protection</b>
                              <br />
                              Entering personal details and signing on behalf of
                              another person is classed as impersonation fraud.
                              To protect our customers, we work with fraud
                              prevention agencies to report and prosecute fraud
                              in all cases.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  ) : null
}

export default RelationshipInfo
