import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { ApplyProvider, useApplyContext } from "./context"
import GuarantorComponentsMap from "./guarantor-components-map"
import AppLoadingOverlay from "./app-loading-overlay"
import AppTermsModal from "./app-terms-modal"
import AppGuarantorRejectedOverlay from "./app-guarantor-rejected-overlay"
import { REST } from "./api-calls-map"

const Guarantor = ({ id }) => {
  const {
    API_MAP,
    setAppLoading,
    appRejected,
    activeStep,
    getLoanApplication,
    setLoggedIn,
  } = useApplyContext()

  useEffect(async () => {
    const checkState = await REST({
      url: `${API_MAP.checkState}`,
      method: "GET",
    })

    checkState && checkState[0] && setLoggedIn(checkState[0].success)

    if (
      checkState &&
      checkState[0] &&
      checkState[0].success &&
      checkState[0].data === "GUARANTOR"
    ) {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      getLoanApplication(urlParams.get("resumeApp"))
    } else {
      setAppLoading(false)
    }
    localStorage.removeItem("moneyReason")
  }, [])

  const GuarantorRenderedComponent = GuarantorComponentsMap[activeStep]

  return (
    <>
      {!appRejected && GuarantorRenderedComponent && (
        <GuarantorRenderedComponent guarantor loanInvitationId={id} />
      )}

      <AppLoadingOverlay />

      <AppGuarantorRejectedOverlay />

      <AppTermsModal />
    </>
  )
}

const GuarantorHOC = props => (
  <ApplyProvider>
    <Guarantor {...props} />
  </ApplyProvider>
)

export default GuarantorHOC
