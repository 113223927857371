import React from "react"
import { Link } from "gatsby"
import { useApplyContext } from "./context"

const AppBorrowerRejectedOverlay = () => {
  const { formState, appRejected } = useApplyContext()

  const retryLoanDate = new Date()

  retryLoanDate.setDate(retryLoanDate.getDate() + 30)

  return appRejected ? (
    <div
      className="bg-white"
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div className="col-11 text-center" style={{ flex: "0" }}>
        <h3>
          Thank you for applying,{" "}
          {formState && formState[1] && formState[1].firstName}!
        </h3>
        <hr />
        <p className="lead-2">
          <h5 className="mb-0 fw-600">
            Unfortunately, we can't offer you a personal loan at this time.
          </h5>{" "}
          A Notice of Adverse Action will be sent to your email address on file.
          We encourage you to re-apply for a loan from Trustic after{" "}
          {retryLoanDate.getMonth() + 1}/{retryLoanDate.getDate()}/
          {retryLoanDate.getFullYear()}
        </p>
        <p>
          Please, visit our <Link to="/hub">Q&A Hub</Link> if any questions.
        </p>
      </div>
    </div>
  ) : null
}

export default AppBorrowerRejectedOverlay
