import RelationshipInfo from "./relationship-info"
import AccountInfo from "./account-info"
import PersonalInfo from "./personal-info"
import AddressInfo from "./address-info"
import IncomeInfo from "./income-info"
import SSNInfo from "./ssn-info"
import LoanOffer from "./loan-offer"
import LivingSituationInfo from "./living-situation-info"
import IncomeDetailsInfo from "./income-details-info"
import RepaymentInfo from "./repayment-info"
import Summary from "./summary"

export default [
  RelationshipInfo,
  AccountInfo,
  PersonalInfo,
  AddressInfo,
  IncomeInfo,
  SSNInfo,
  LoanOffer,
  LivingSituationInfo,
  IncomeDetailsInfo,
  RepaymentInfo,
  Summary,
]
