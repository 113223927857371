import React, { useState } from "react"
import { navigate } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { GoogleLogin } from "react-google-login"
import FormikField from "./formik-field"
import { ApplyProvider, useApplyContext } from "./context"
import { REST } from "./api-calls-map"
import Modal from "./modal"
import AppTermsModal from "./app-terms-modal"

const AccountInfoSchema = Yup.object().shape({
  password: Yup.string().required("Password required."),
  username: Yup.string().email("Invalid email").required("Email required."),
})

const isBrowser = typeof window !== "undefined"

const Login = () => {
  const {
    API_MAP,
    stepLoading,
    setStepLoading,
    setShowTermsModal,
    setTermsModalContent,
  } = useApplyContext()

  const breakpoints = useBreakpoint()

  const [googleError, setGoogleError] = useState(null)

  const responseGoogle = async response => {
    if (response.error) {
      setGoogleError(response.error)
      return false
    }

    if (response.accessToken) {
      const [res, status] = await REST({
        url: API_MAP.authenticateUserWithGoogle,
        method: "POST",
        body: {
          accessToken: response.accessToken,
        },
      })

      if (status === 200) {
        localStorage.setItem("Authenticated", true)

        if (
          res.data &&
          !res.data.guarantorApplicationBusinessId &&
          res.data.loanApplicationBusinessId
        ) {
          localStorage.setItem("businessId", res.data.loanApplicationBusinessId)
        }

        if (
          res.data &&
          res.data.guarantorApplicationBusinessId &&
          res.data.loanApplicationBusinessId
        ) {
          localStorage.setItem(
            "businessId",
            res.data.guarantorApplicationBusinessId
          )
          navigate(
            `/apply/guarantor/${res.data.loanApplicationBusinessId}?resumeApp=true`
          )
        } else {
          navigate("/apply")
        }
      }
    }
  }

  return (
    <>
      <div
        style={{
          minHeight: breakpoints.md
            ? "calc(100vh - 141px)"
            : "calc(100vh - 69px)",
        }}
      >
        <header className="header pt-9 pb-6">
          <div className="container">
            <div className="bg-white rounded shadow-7 w-400 mw-100 p-6 m-auto">
              <h5 className="mb-5 text-center">Sign in</h5>
              <p className="text-center">
                You should have received your account credentials in the
                "Welcome to Trustic" email on the day of your registration.
              </p>
              <Formik
                initialValues={{
                  username: "",
                  password: "",
                }}
                onSubmit={async values => {
                  const ModalInstance = Modal.mixin({
                    customClass: {
                      confirmButton: "btn btn-dark btn-xl fw-600 w-40",
                      cancelButton: "btn btn-danger btn-xl fw-600 w-40",
                    },
                    buttonsStyling: false,
                  })

                  try {
                    setStepLoading(true)
                    const [res, status] = await REST({
                      url: API_MAP.login,
                      method: "POST",
                      body: values,
                    })

                    if (status === 200) {
                      localStorage.setItem("Authenticated", true)

                      if (
                        res.data &&
                        !res.data.guarantorApplicationBusinessId &&
                        res.data.loanApplicationBusinessId
                      ) {
                        localStorage.setItem(
                          "businessId",
                          res.data.loanApplicationBusinessId
                        )
                      }

                      if (
                        res.data &&
                        res.data.guarantorApplicationBusinessId &&
                        res.data.loanApplicationBusinessId
                      ) {
                        localStorage.setItem(
                          "businessId",
                          res.data.guarantorApplicationBusinessId
                        )
                        navigate(
                          `/apply/guarantor/${res.data.loanApplicationBusinessId}?resumeApp=true`
                        )
                      } else {
                        navigate("/apply")
                      }
                    }

                    setStepLoading(false)
                  } catch (error) {
                    return ModalInstance.fire({
                      icon: "error",
                      title:
                        "<strong style='text-transform: uppercase'>Oops...</strong>",
                      text: "Something went wrong.",
                      confirmButtonText: "Ok",
                    }).then(() => {
                      setStepLoading(false)
                    })
                  }
                }}
                validationSchema={AccountInfoSchema}
              >
                {formikProps => (
                  <Form>
                    <FormikField
                      type="text"
                      name="username"
                      placeholder="Email"
                    />
                    <FormikField
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                    <div className="form-group">
                      <button
                        className="btn btn-xl btn-block btn-dark"
                        type="submit"
                        disabled={!formikProps.isValid}
                      >
                        {stepLoading ? (
                          <div
                            className="loader"
                            style={{
                              borderColor: "rgb(255 255 255 / 20%)",
                              borderLeftColor: "#fff",
                              fontSize: "2.4px",
                            }}
                          />
                        ) : (
                          "Login"
                        )}
                      </button>
                      <div
                        style={{
                          margin: "20px 0",
                          width: "100%",
                          height: "1px",
                          position: "relative",
                          backgroundColor: "#757575",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "-10px",
                            left: "50%",
                            width: "40px",
                            height: "20px",
                            marginLeft: "-20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            fontSize: "14px",
                            color: "#757575",
                          }}
                        >
                          OR
                        </div>
                      </div>
                      <GoogleLogin
                        className="btn btn-xl btn-block w-100 d-flex justify-content-center align-items-center"
                        clientId={process.env.GOOGLE_CLIENT_ID}
                        buttonText="Login with Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={"single_host_origin"}
                        scope={"profile email"}
                        fetchBasicProfile={false}
                        disabled={googleError}
                      />
                      {googleError && (
                        <p class="text-center mt-4 mb-0">
                          <code>
                            Your browser is currently blocking third-party
                            cookies. To sign in with Google, please enable
                            third-party cookies from your browser settings.
                          </code>
                        </p>
                      )}
                      <p className="mt-6 text-center fs-14">
                        Forgot your password? Click
                        <a
                          href="#"
                          onClick={e => {
                            e.stopPropagation()
                            navigate("/apply/reset-password")
                          }}
                        >
                          {" "}
                          here{" "}
                        </a>
                        to reset.
                      </p>
                      <p className="text-center">
                        Having trouble? Email{" "}
                        <a href="mailto:support@trustic.com" target="_blank">
                          support@trustic.com
                        </a>
                      </p>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </header>
      </div>
      <AppTermsModal />
    </>
  )
}

const LoginHOC = () => (
  <ApplyProvider>
    <Login />
  </ApplyProvider>
)

export default LoginHOC
