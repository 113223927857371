import React from "react"
import { Formik, Form } from "formik"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import * as Yup from "yup"
import "yup-phone"
import { useApplyContext } from "./context"
import StepHeader from "./step-header"
import FormikField from "./formik-field"

/*global localStorage */
const SSNInfoSchema = Yup.object().shape({
  ssn: Yup.string()
    .matches(
      "^(?!000)[0-8][0-9]{2}-[0-9]{2}-(?!0000)[0-9]{4}$",
      "Please enter a valid SSN number."
    )
    .required("SSN required."),
  consent: Yup.bool().oneOf(
    [true],
    "Please, check the box to confirm you’ve read and agree to the terms above."
  ),
})

const SSNInfoSchema2 = Yup.object().shape({
  ssn: Yup.string(),
  consent: Yup.bool().oneOf(
    [true],
    "Please, check the box to confirm you’ve read and agree to the terms above."
  ),
})

/*global dataLayer */
const SSNInfo = ({ guarantor }) => {
  const {
    API_MAP,
    setShowTermsModal,
    stepLoading,
    activeStep,
    setActiveStep,
    formState,
    submitStepAndUpdateAppState,
    isNotFirstApplication,
  } = useApplyContext()

  const activeStepState = formState[activeStep]

  const breakpoints = useBreakpoint()

  return (
    <header className="header pt-8 pb-4 mh-100vh">
      <div className="container">
        <div className="row">
          <div className="col-md-7 mx-auto text-left">
            <div className="card">
              <div className="card-body py-0 px-0 py-md-0">
                <StepHeader
                  activeStep={activeStep}
                  title="You are almost there! Please, provide your Social Security Number"
                  description="We want to make sure we fetch the right credit report*."
                />
                <Formik
                  enableReinitialize
                  validateOnChange={true}
                  initialValues={activeStepState}
                  onSubmit={values => {
                    if (isNotFirstApplication && activeStepState.ssn) {
                      delete values.ssn
                    }
                    submitStepAndUpdateAppState(
                      guarantor
                        ? API_MAP.updateGuarantorApplication
                        : API_MAP.loanApplication,
                      { ...values, submitAction: true }
                    )
                    if (!guarantor) {
                      dataLayer.push({ event: "borrower_step5_submitted" })
                    }
                  }}
                  validationSchema={
                    isNotFirstApplication && activeStepState.ssn
                      ? SSNInfoSchema2
                      : SSNInfoSchema
                  }
                >
                  {formikProps => (
                    <Form>
                      <div className="row no-gap mb-5">
                        <div className="col-md-12">
                          {isNotFirstApplication && activeStepState.ssn ? (
                            <FormikField
                              type="text"
                              name="ssn"
                              placeholder="SSN"
                              disabled
                            />
                          ) : (
                            <FormikField
                              type="ssn"
                              name="ssn"
                              placeholder="SSN"
                            />
                          )}
                          <p className="mb-2">
                            <small>
                              <b>
                                By checking this box, I've read and agree to:
                              </b>
                            </small>
                          </p>
                          <FormikField
                            type="checkbox"
                            name="consent"
                            placeholder={
                              <>
                                <a
                                  href="#"
                                  onClick={e => {
                                    e.preventDefault()
                                    setShowTermsModal("terms")
                                  }}
                                >
                                  The Terms of Use
                                </a>
                                ,{" "}
                                <a
                                  href="#"
                                  onClick={e => {
                                    e.preventDefault()
                                    setShowTermsModal("privacy")
                                  }}
                                >
                                  Privacy Policy
                                </a>
                                ,{" "}
                                <a
                                  href="#"
                                  onClick={e => {
                                    e.preventDefault()
                                    setShowTermsModal("esign")
                                  }}
                                >
                                  ESIGN Act Consent
                                </a>
                                , and{" "}
                                <a
                                  href="#"
                                  onClick={e => {
                                    e.preventDefault()
                                    setShowTermsModal("credit")
                                  }}
                                >
                                  Credit Profile Authorization.
                                </a>
                              </>
                            }
                          />
                        </div>
                        {breakpoints.md ? (
                          <div className="row col-12 mx-0">
                            <div className="col-2 px-0">
                              <button
                                type="button"
                                className="btn btn-lg btn-dark btn-block px-0"
                                onClick={() => setActiveStep(activeStep - 1)}
                              >
                                <span className="ti-angle-left"></span>
                              </button>
                            </div>
                            <div className="col-10">
                              <button
                                type="submit"
                                className="btn btn-lg btn-dark btn-block"
                              >
                                {stepLoading ? (
                                  <div
                                    className="loader"
                                    style={{
                                      borderColor: "rgb(255 255 255 / 20%)",
                                      borderLeftColor: "#fff",
                                      fontSize: "2.4px",
                                    }}
                                  />
                                ) : guarantor ? (
                                  "Get Approved"
                                ) : (
                                  "Get Your Rate"
                                )}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="row col-6 offset-3">
                            <div className="col-2 px-0">
                              <button
                                type="button"
                                className="btn btn-lg btn-dark btn-block px-0"
                                onClick={() => setActiveStep(activeStep - 1)}
                              >
                                <span className="ti-angle-left"></span>
                              </button>
                            </div>
                            <div className="col-10">
                              <button
                                type="submit"
                                className="btn btn-lg btn-dark btn-block"
                              >
                                {stepLoading ? (
                                  <div
                                    className="loader"
                                    style={{
                                      borderColor: "rgb(255 255 255 / 20%)",
                                      borderLeftColor: "#fff",
                                      fontSize: "2.4px",
                                    }}
                                  />
                                ) : guarantor ? (
                                  "Get Approved"
                                ) : (
                                  "Get Your Rate"
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
                <p>
                  {guarantor ? (
                    <small>
                      By getting approved as a Guarantor generates a soft credit
                      inquiry, which is visible only to you.
                    </small>
                  ) : (
                    <small>
                      * Checking your rate generates a soft credit inquiry,
                      which is visible only to you. A hard credit inquiry that
                      may affect your credit score only appears when your loan
                      is funded.
                    </small>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default SSNInfo
