import React from "react"
import { withPrefix } from "gatsby"

const isBrowser = typeof window !== "undefined"

const domain =
  isBrowser &&
  window &&
  window.location &&
  window.location.origin &&
  window.location.origin &&
  window.location.origin === "http://local.dev.trustic.com:8000"
    ? "http://dev.trustic.com"
    : isBrowser && window.location.origin

const GuarantorStatus = ({ loanApplication }) => {
  return (
    <>
      {!loanApplication.guarantorApplicationStatus &&
      !loanApplication.guarantorFirstName ? (
        <p className="lead-2">
          You need to find a Guarantor in order to finalize your loan.
          <br />
          Please, check out our "How to find a Guarantor" guide{" "}
          <a
            href={withPrefix("how-to-find-a-guarantor.pdf")}
            download
            target="_blank"
          >
            here.
          </a>
          <br />
          Once you have a Guarantor, please, share this application link with
          them, so that they can complete their part of the application:{" "}
          <a
            href={
              loanApplication &&
              `${domain}/apply/guarantor/${loanApplication.businessId}`
            }
            target="_blank"
          >
            {loanApplication &&
              `${domain}/apply/guarantor/${loanApplication.businessId}`}
          </a>
          . <br />
          Please, contact us if you need any help at support@trustic.com or
          888-429-0480.
        </p>
      ) : null}
      {loanApplication.guarantorApplicationStatus === "REJECTED" ? (
        <p className="lead-2">
          Unfortunately{" "}
          {loanApplication.guarantorFirstName
            ? loanApplication.guarantorFirstName
            : "Somebody"}{" "}
          could not be accepted as your guarantor. You need to find another
          Guarantor in order to finalize your loan. Please, check out our "How
          to find a Guarantor" guide{" "}
          <a
            href={withPrefix("how-to-find-a-guarantor.pdf")}
            download
            target="_blank"
          >
            here.
          </a>{" "}
          Once you have a Guarantor, please, share this application link with
          them, so that they can complete their part of the application:{" "}
          <a
            href={
              loanApplication &&
              `${domain}/apply/guarantor/${loanApplication.businessId}`
            }
            target="_blank"
          >
            {loanApplication &&
              `${domain}/apply/guarantor/${loanApplication.businessId}`}
          </a>
          .
          <br />
          Please, contact us if you need any help at support@trustic.com or
          888-429-0480.
        </p>
      ) : null}
      {loanApplication.guarantorApplicationStatus === "STARTED" ? (
        <p className="lead-2">
          {loanApplication.guarantorFirstName
            ? loanApplication.guarantorFirstName
            : "Somebody"}{" "}
          has started their guarantor application. They need to finish their
          guarantor application in order for your loan to be finalized.
          <br />
          Please, contact us if you need any help at support@trustic.com or
          888-429-0480.
        </p>
      ) : null}
      {loanApplication.guarantorApplicationStatus === "CONDITIONAL_APPROVED" ? (
        <p className="lead-2">
          {loanApplication.guarantorFirstName
            ? loanApplication.guarantorFirstName
            : "Somebody"}{" "}
          has been accepted as your Guarantor. They need to finish their
          guarantor application and sign their personal guarantee agreement in
          order for your loan to be finalized.
          <br />
          Please, contact us if you need any help at support@trustic.com or
          888-429-0480.
        </p>
      ) : null}
      {loanApplication.guarantorApplicationStatus === "CONTRACT_SIGNED" ? (
        <p className="lead-2">
          {loanApplication.guarantorFirstName
            ? loanApplication.guarantorFirstName
            : "Somebody"}{" "}
          has signed their personal guarantee agreement. We might have to verify
          some of the information they have submitted. Please, advice{" "}
          {loanApplication.guarantorFirstName
            ? loanApplication.guarantorFirstName
            : "Somebody"}{" "}
          to check their email for any outstanding items.
          <br />
          Please, contact us if you need any help at support@trustic.com or
          888-429-0480.
        </p>
      ) : null}
      {loanApplication.guarantorApplicationStatus === "APPROVED" ? (
        <p className="lead-2">
          {loanApplication.guarantorFirstName
            ? loanApplication.guarantorFirstName
            : "Somebody"}{" "}
          has been approved as your guarantor.
          <br />
          Please, contact us if you need any help at support@trustic.com or
          888-429-0480.
        </p>
      ) : null}
    </>
  )
}

export default GuarantorStatus
