import React, { useState } from "react"
import { Link } from "gatsby"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import "yup-phone"
import { GoogleLogin } from "react-google-login"
import { useApplyContext } from "./context"
import StepHeader from "./step-header"
import FormikField from "./formik-field"
import { REST } from "./api-calls-map"

const isBrowser = typeof window !== "undefined"

Yup.addMethod(Yup.string, "isPhoneNumberValid", function () {
  return this.test(`test-date-of-birth`, "", function (value) {
    const { path, createError } = this

    if (!!value) {
      if (
        value
          .replace(" ", "")
          .replace("(", "")
          .replace(")", "")
          .replace("-", "")
          .split("_").length > 1
      ) {
        return createError({ path, message: "Iconrect Phone Number" })
      }
    }

    return value
  })
})

const AccountInfoEmailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email required."),
})

const AccountInfoPhoneSchema = Yup.object().shape({
  phone: Yup.string().isPhoneNumberValid().required("Phone required."),
})

/*global dataLayer */
const AccountInfo = ({ guarantor }) => {
  const {
    API_MAP,
    setShowTermsModal,
    stepLoading,
    activeStep,
    formState,
    submitStepAndUpdateAppState,
    isNotFirstApplication,
  } = useApplyContext()

  const activeStepState = formState[activeStep]

  const [signUpView, setSignUpView] = useState(true)

  const [email, setEmail] = useState(null)

  const [googleAccessToken, setGoogleAccessToken] = useState(null)

  const [googleError, setGoogleError] = useState(null)

  const responseGoogle = async response => {
    if (response.error) {
      setGoogleError(response.error)
      return false
    }

    if (response.accessToken) {
      setGoogleAccessToken(response.accessToken)
      setSignUpView(false)
    }
  }

  return (
    <header className="header pt-8 pb-4 mh-100vh">
      <div className="container">
        <div className="row">
          <div className="col-md-7 mx-auto text-left">
            <div className="card">
              {signUpView ? (
                <div className="card-body py-0 px-0 py-md-0">
                  <StepHeader
                    activeStep={activeStep}
                    title={
                      isNotFirstApplication
                        ? "Let’s get started. We’ve pre-filled your info based on your previous application. Please, review and edit when needed."
                        : guarantor
                        ? "What’s your email address?"
                        : "Let’s get started. What is your email address?"
                    }
                    description="You will use your email to sign in and manage your loan."
                  />
                  <Formik
                    key="email_form"
                    enableReinitialize
                    isInitialValid={isNotFirstApplication}
                    initialValues={activeStepState}
                    onSubmit={async (values, { setFieldError }) => {
                      if (!guarantor) {
                        dataLayer.push({ event: "borrower_step1_submitted" })
                      }

                      setSignUpView(false)
                      setEmail(values.email)
                    }}
                    validationSchema={AccountInfoEmailSchema}
                  >
                    {formikProps => {
                      return (
                        <Form>
                          <div className="row no-gap mb-5">
                            <div className="col-md-12">
                              <FormikField
                                type="text"
                                name="email"
                                placeholder="Your email address"
                              />
                            </div>
                            <div className="col-12">
                              <button
                                type="submit"
                                className="btn btn-lg btn-dark btn-block"
                                disabled={!formikProps.isValid}
                              >
                                {stepLoading ? (
                                  <div
                                    className="loader"
                                    style={{
                                      borderColor: "rgb(255 255 255 / 20%)",
                                      borderLeftColor: "#fff",
                                      fontSize: "2.4px",
                                    }}
                                  />
                                ) : (
                                  "Continue with Email"
                                )}
                              </button>
                              <div
                                style={{
                                  margin: "20px 0",
                                  width: "100%",
                                  height: "1px",
                                  position: "relative",
                                  backgroundColor: "#757575",
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "-10px",
                                    left: "50%",
                                    width: "40px",
                                    height: "20px",
                                    marginLeft: "-20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#fff",
                                    fontSize: "14px",
                                    color: "#757575",
                                  }}
                                >
                                  OR
                                </div>
                              </div>
                              <GoogleLogin
                                className="btn btn-xl btn-block w-100 d-flex justify-content-center align-items-center"
                                clientId={process.env.GOOGLE_CLIENT_ID}
                                buttonText="Continue with Google"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={"single_host_origin"}
                                scope={"profile email"}
                                fetchBasicProfile={false}
                                disabled={googleError}
                              />
                              {googleError && (
                                <p class="text-center mt-4 mb-0">
                                  <code>
                                    Your browser is currently blocking
                                    third-party cookies. To sign in with Google,
                                    please enable third-party cookies from your
                                    browser settings.
                                  </code>
                                </p>
                              )}
                            </div>
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              ) : (
                <div className="card-body py-0 px-0 py-md-0">
                  <StepHeader
                    activeStep={activeStep}
                    title="What is your mobile phone number?"
                    // description="You will use your email to sign in and manage your loan."
                  />
                  <Formik
                    key="password_form"
                    enableReinitialize
                    isInitialValid={isNotFirstApplication}
                    initialValues={activeStepState}
                    onSubmit={async (values, { setFieldError }) => {
                      let baseUrl = null

                      if (isNotFirstApplication) {
                        baseUrl = guarantor
                          ? API_MAP.updateGuarantorApplication
                          : API_MAP.loanApplication
                      } else {
                        baseUrl = guarantor
                          ? API_MAP.registerAndInitiateGuarantorApplication
                          : API_MAP.borrowerAuth
                      }

                      if (googleAccessToken) {
                        baseUrl = guarantor
                          ? API_MAP.registerAndInitiateGuarantorApplicationWithGoogle
                          : API_MAP.registerAndInitiateLoanApplicationWithGoogle
                        values.accessToken = googleAccessToken
                      }

                      if (email) {
                        values.email = email
                      }

                      const [res, status] = await submitStepAndUpdateAppState(
                        baseUrl,
                        {
                          applicationBusinessId: localStorage.getItem(
                            "businessId"
                          ),
                          submitAction: false,
                          ...values,
                        },
                        true
                      )

                      if (!guarantor) {
                        dataLayer.push({ event: "borrower_step1_submitted" })
                      }

                      if (
                        res &&
                        res.message ===
                          "Please, enter your own mobile phone number."
                      ) {
                        setFieldError("phone", res.errors[0].message)
                      }

                      if (
                        res &&
                        res.message ===
                          "There is another account using the same email address."
                      ) {
                        setFieldError("email", res.errors[0].message)
                      }
                    }}
                    validationSchema={AccountInfoPhoneSchema}
                  >
                    {formikProps => {
                      return (
                        <Form>
                          <div className="row no-gap mb-5">
                            <div className="col-md-12">
                              <FormikField
                                type="tel"
                                name="phone"
                                placeholder="Your mobile phone"
                              />
                              <FormikField
                                type="checkbox"
                                name="marketingConsent"
                                placeholder={
                                  <>
                                    Stay in the loop! Keep updated with all the
                                    latest from Trustic. We'll also let you know
                                    when you can apply to borrow more money with
                                    us! By choosing this option you agree to the{" "}
                                    <Link
                                      to="/terms"
                                      onClick={e => {
                                        e.preventDefault()
                                        setShowTermsModal("terms")
                                      }}
                                    >
                                      Terms of Use
                                    </Link>{" "}
                                    and{" "}
                                    <Link
                                      to="/privacy"
                                      onClick={e => {
                                        e.preventDefault()
                                        setShowTermsModal("privacy")
                                      }}
                                    >
                                      Privacy Policy
                                    </Link>
                                    .
                                  </>
                                }
                              />
                            </div>
                            <div className="row col-8 offset-2">
                              <div className="col-2 px-0">
                                <Link
                                  to="/"
                                  className="btn btn-lg btn-dark btn-block px-0"
                                >
                                  <span className="ti-angle-left text-white"></span>
                                </Link>
                              </div>
                              <div className="col-10">
                                <button
                                  type="submit"
                                  className="btn btn-lg btn-dark btn-block"
                                  disabled={!formikProps.isValid}
                                >
                                  {stepLoading ? (
                                    <div
                                      className="loader"
                                      style={{
                                        borderColor: "rgb(255 255 255 / 20%)",
                                        borderLeftColor: "#fff",
                                        fontSize: "2.4px",
                                      }}
                                    />
                                  ) : (
                                    "Next"
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                  <div className="card-body border px-6 mb-5">
                    <div className="rating mb-3">
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                    </div>
                    <p
                      className="text-quoted mb-0"
                      style={{ minHeight: "56px" }}
                    >
                      <small>
                        “Trustic helped me when I needed it most, with a
                        relative as my guarantor, they lent me enough money to
                        pay off my debt and give me peace of mind. Thank you!”
                      </small>
                    </p>
                  </div>
                  <div className="card-body border px-6 mb-5">
                    <div className="rating mb-3">
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                    </div>
                    <p
                      className="text-quoted mb-0"
                      style={{ minHeight: "56px" }}
                    >
                      <small>
                        “Really helpful team. The team kept me updated on every
                        stage till the end. Very patient, professional and
                        precise. They are very quick as well.”
                      </small>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default AccountInfo
