import React from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import "yup-phone"
import { useApplyContext } from "./context"
import StepHeader from "./step-header"
import FormikField from "./formik-field"

const LivingSituationInfoSchema = Yup.object().shape({
  livingSituation: Yup.string().required("Living Situation required."),
})

/*global dataLayer */
const LivingSituationInfo = ({ guarantor }) => {
  const {
    API_MAP,
    stepLoading,
    activeStep,
    formState,
    submitStepAndUpdateAppState,
  } = useApplyContext()

  const activeStepState = formState[activeStep]

  return (
    <header className="header pt-8 pb-4 mh-100vh">
      <div className="container">
        <div className="row">
          <div className="col-md-7 mx-auto text-left">
            <div className="card">
              <div className="card-body py-0 px-0 py-md-0">
                <StepHeader
                  title="What's your current living situation?"
                  description="Help us get a better sense of your overall expenses."
                />
                <Formik
                  initialValues={activeStepState}
                  onSubmit={values => {
                    submitStepAndUpdateAppState(
                      API_MAP.updateLivingSituation,
                      values
                    )
                    if (!guarantor) {
                      dataLayer.push({
                        event: "borrower_add_details_step1_submitted",
                      })
                      dataLayer.push({
                        borrower_living_situation: values.livingSituation,
                      })
                    }
                  }}
                  validationSchema={LivingSituationInfoSchema}
                >
                  {formikProps => (
                    <Form>
                      <div className="row no-gap mb-5">
                        <div className="col-md-12">
                          <div className="custom-controls-stacked">
                            <FormikField type="select" name="livingSituation">
                              <option value="" disabled selected>
                                Select an option
                              </option>
                              <option value="RENT">I pay rent</option>
                              <option value="MORTGAGE">I pay a mortgage</option>
                              <option value="FULLY_OWN">I fully own</option>
                            </FormikField>
                          </div>
                        </div>
                        <div className="row col-8 offset-2">
                          <div className="col-10">
                            <button
                              type="submit"
                              className="btn btn-lg btn-dark btn-block"
                              disabled={!formikProps.isValid}
                            >
                              {stepLoading ? (
                                <div
                                  className="loader"
                                  style={{
                                    borderColor: "rgb(255 255 255 / 20%)",
                                    borderLeftColor: "#fff",
                                    fontSize: "2.4px",
                                  }}
                                />
                              ) : (
                                "Next"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default LivingSituationInfo
