import React from "react"
import { useApplyContext } from "./context"

const SummaryActiveGuarantor = ({ setSignAgreement }) => {
  const { loanApplication } = useApplyContext()

  return (
    <>
      <div className="card border mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <p>
                <b>Loan Amount</b>
                <br />$
                {loanApplication.borrowerOffer.loanAmount &&
                  loanApplication.borrowerOffer.loanAmount.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
              </p>
              <p>
                <b>Monthly Payment</b>
                <br />$
                {loanApplication.borrowerOffer.monthlyPayment &&
                  loanApplication.borrowerOffer.monthlyPayment.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
              </p>
            </div>
            <div className="col-6">
              <p>
                <b>Loan Term</b>
                <br />
                {loanApplication.borrowerOffer.loanTermMonths} Months
              </p>
              <p>
                <b>APR</b>
                <br />
                {loanApplication.borrowerOffer.apr &&
                  loanApplication.borrowerOffer.apr.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                %
              </p>
            </div>
          </div>
        </div>
      </div>
      <h4 className="fw-300">
        Congratulations, your guarantor application has been approved.
      </h4>
      <p className="lead-1">
        Once {loanApplication.borrowerFirstName} completes their part of the
        application, their loan will be funded to your bank account. Then it
        will be your responsibility to give the funds to{" "}
        {loanApplication.borrowerFirstName}.
      </p>
      <p className="lead-1">
        Funds are generally deposited via ACH for delivery next business day
        after approval if approved by 2pm Eastern Monday-Friday.
      </p>
    </>
  )
}

export default SummaryActiveGuarantor
