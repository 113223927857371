import React from "react"
import { useApplyContext } from "./context"

const isBrowser = typeof window !== "undefined"

const SummaryIdleLoan = () => {
  const { loanApplication } = useApplyContext()

  return (
    <>
      {loanApplication.acceptedLoanOffer && (
        <div className="card border mb-4">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <p>
                  <b>Loan Amount</b>
                  <br />$
                  {loanApplication.acceptedLoanOffer.loanAmount &&
                    loanApplication.acceptedLoanOffer.loanAmount.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                </p>
                <p>
                  <b>Monthly Payment</b>
                  <br />$
                  {loanApplication.acceptedLoanOffer.monthlyPayment &&
                    loanApplication.acceptedLoanOffer.monthlyPayment.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                </p>
              </div>
              <div className="col-6">
                <p>
                  <b>Loan Term</b>
                  <br />
                  {loanApplication.acceptedLoanOffer.loanTermMonths} Months
                </p>
                <p>
                  <b>APR</b>
                  <br />
                  {loanApplication.acceptedLoanOffer.apr &&
                    loanApplication.acceptedLoanOffer.apr.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  %
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <h4 className="fw-300">
        Just a few more things before your loan is finalised.
      </h4>
      <p className="lead-1">
        {/* We need to verify information that you have provided. We will let you
        know by email or phone call if we require anything else. Once we verify
        your information (and your Guarantor information if you’ve chosen
        Guarantor Personal Loan), your loan will be finalized and ready to be
        funded. */}
        We need to verify the information that you provided. Once verified, your
        loan will be finalized and ready to be funded.
      </p>
    </>
  )
}

export default SummaryIdleLoan
