import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { ApplyProvider, useApplyContext } from "./context"
import BorrowerComponentsMap from "./borrower-components-map"
import AppLoadingOverlay from "./app-loading-overlay"
import AppTermsModal from "./app-terms-modal"
import AppBorrowerRejectedOverlay from "./app-borrower-rejected-overlay"
import { REST } from "./api-calls-map"

const Apply = () => {
  const {
    API_MAP,
    setAppLoading,
    setAppLoadingStatus,
    appRejected,
    activeStep,
    getLoanApplication,
    setLinkBankLoading,
    setLoggedIn,
  } = useApplyContext()

  useEffect(async () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    const linkToken = localStorage.getItem("linkToken")

    const loanInvitationId = localStorage.getItem("loanInvitationId")

    if (urlParams.get("oauth_state_id") && loanInvitationId && linkToken) {
      navigate(`/apply/guarantor/${loanInvitationId}`)
      setLinkBankLoading(true)
      setAppLoading(true)
      setAppLoadingStatus("Initializing Bank Account Linking")
      navigate(
        `/apply/guarantor/${loanInvitationId}?oauth_state_id=${urlParams.get(
          "oauth_state_id"
        )}`
      )
      return
    }

    !localStorage.getItem("businessId") && navigate(-1)

    const checkState = await REST({
      url: `${API_MAP.checkState}`,
      method: "GET",
    })

    if (checkState && checkState[0] && checkState[0].success) {
      setLoggedIn(true)
      getLoanApplication()
    } else {
      setLoggedIn(false)
      if (!localStorage.getItem("moneyReason")) {
        navigate("/")
      }
      setAppLoading(false)
    }
    localStorage.removeItem("moneyReason")
  }, [])

  const BorrowerRenderedComponent = BorrowerComponentsMap[activeStep]

  return (
    <>
      {!appRejected && BorrowerRenderedComponent && (
        <BorrowerRenderedComponent />
      )}

      <AppLoadingOverlay />

      <AppBorrowerRejectedOverlay />

      <AppTermsModal />
    </>
  )
}

const ApplyHOC = () => (
  <ApplyProvider>
    <Apply />
  </ApplyProvider>
)

export default ApplyHOC
